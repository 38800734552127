import React from "react";
import Skeleton from "@mui/material/Skeleton";
import { Box, Grid } from "@mui/material";

const Loader = () => {
  return (
    <>
      <Box>
        <Skeleton
          variant="rectangular"
          width="20%"
          height={15}
          style={{ marginBottom: ".5rem" }}
        />
        <Skeleton variant="rectangular" width="25%" height={15} />
      </Box>
      <Box mt={2}>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Box>
              <Skeleton
                variant="rounded"
                width="100%"
                height={130}
                style={{ marginBottom: ".75rem" }}
              />
              <Skeleton variant="rounded" width="100%" height={125} />
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box>
              <Skeleton variant="rounded" width="100%" height={270} />
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box>
              <Skeleton variant="rounded" width="100%" height={270} />
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box mt={2}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Box>
              <Skeleton variant="rounded" width="100%" height={250} />
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box>
              <Skeleton variant="rounded" width="100%" height={250} />
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box mt={2}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Box>
              <Skeleton variant="rounded" width="100%" height={250} />
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box>
              <Skeleton variant="rounded" width="100%" height={250} />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export { Loader };
