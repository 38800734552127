import React from "react";
import { NavLink } from "react-router-dom";
import Box from "@mui/material/Box";
import Home from "../assets/images/home-icon.svg";
import Ai from "../assets/images/chat-bot.svg";
import CLTV from "../assets/images/cltv-icon.svg";
import Cohort from "../assets/images/cohort-icon.svg";
import Competitive from "../assets/images/competitive-icon.svg";
import DashBoardLogo from "../assets/images/dashboard-icon.svg";
import aclisLogo from "../assets/images/logo-aclis.svg";
import SVG from "react-inlinesvg";
import { Link, useLocation } from "react-router-dom";
import "./SideBar.css";

const SideBar = () => {
  const location = useLocation();
  // const drawerWidth = 90;

  return (
    <Box>
      {/* <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          },
        }}
        variant="permanent"
        anchor="left"
      >
        <Toolbar />
        <Divider />
        <List>
          {[
            { path: "/home", icon: Home, text: "Home" },
            { path: "/dashboard", icon: DashBoardLogo, text: "Dashboard" },
            {
              path: "/competitiveanalysis",
              icon: Competitive,
              text: "Competitive Analysis",
            },
            { path: "/cohortanalysis", icon: Cohort, text: "Cohort" },
            { path: "/cltvanalysis", icon: CLTV, text: "CLTV" },
            { path: "/chatbot", icon: Ai, text: "AI Chatbot" },
          ].map((item, index) => (
            <ListItem key={index} disablePadding>
              <Link to={item.path}>
                <img src={item.icon} alt="logo" />
                <Typography
                  variant="caption"
                  sx={{ fontSize: "10px", marginTop: "4px" }}
                >
                  {item.text}
                </Typography>
              </Link>
            </ListItem>
          ))}
        </List>
      </Drawer> */}
      <Box
        sx={{
          zIndex: "1200",
          flexDirection: "column",
          alignItems: "center",
          gap: "2rem",
          "li a": {
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            justifyContent: "center",
            padding: "0.5rem 0",
            gap: "0.25rem",
            textDecoration: "none",
            fontSize: "0.625rem",
            color: "#292A31",
            borderRadius: "8px",
            transition: "all 0.25s",
            textAlign: "center",
            "&:hover,&.active": {
              backgroundColor: "#FEEDEC",
              color: "#A91E1D",
              "svg path": {
                stroke: "#A91E1D",
              },
            },
            "&.disabled": {
              pointerEvents: "none",
            },
          },
        }}
        className="sidebar"
      >
        {location.pathname.split("/").includes("demopage") ? (
          <img src={aclisLogo} alt="aclis" />
        ) : (
          <Link to="/dashboard" disabled>
            {" "}
            <img src={aclisLogo} alt="aclis" />
          </Link>
        )}
        <ul>
          <li>
            {location.pathname.split("/").includes("demopage") ? (
              <NavLink to="/demopage2" className="nav-link disabled" end>
                <SVG src={Home} alt="Home" />
                <span>Home</span>
              </NavLink>
            ) : (
              <></>
              // <NavLink to="/home" className="nav-link" end>
              //   <SVG src={Home} alt="Home" />
              //   <span>Home</span>
              // </NavLink>
            )}
          </li>
          <li>
            {location.pathname.split("/").includes("demopage") ? (
              <NavLink to="/demopage1" className="nav-link disabled" end>
                <SVG src={DashBoardLogo} alt="Dashboard" />
                <span>Dashboard</span>
              </NavLink>
            ) : (
              <NavLink to="/dashboard" className="nav-link" end>
                <SVG src={DashBoardLogo} alt="Dashboard" />
                <span>Dashboard</span>
              </NavLink>
            )}
          </li>
          <li>
            <NavLink to="/cohortanalysis" className="nav-link" end>
              <SVG src={Cohort} alt="Cohort Analysis" />
              <span>Cohort</span>
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/competitiveanalysis"
              className="nav-link disabled"
              end
            >
              <SVG src={Competitive} alt="Competitive Analysis" />
              <span>Competitive Analysis</span>
            </NavLink>
          </li>
          <li>
            <NavLink to="/cltvanalysis" className="nav-link disabled" end>
              <SVG src={CLTV} alt="CLTV Analysis" />
              <span>CLTV </span>
            </NavLink>
          </li>
          <li>
            <NavLink to="/chatbot" className="nav-link disabled" end>
              <SVG src={Ai} alt="Chatbot" />
              <span>Chatbot</span>
            </NavLink>
          </li>
        </ul>
      </Box>
    </Box>
  );
};

export { SideBar };
