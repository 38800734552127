import React, { useEffect, useState } from "react"; 
import Carousel from 'react-material-ui-carousel';
import {Container, Paper , Box , Typography, Button} from "@mui/material";
import logoSplash from '../components/assets/images/LogoSplash.png';
import aclisSplash from '../components/assets/images/aclisSplash.png';
import analyseSampleReport from '../components/assets/images/analyseSampleReport.png';
import sampleCarousel1 from '../components/assets/images/sampleCarousel1.png';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate  } from "react-router-dom";
import { useSampleSizeData } from "../../src/hooks/chartHook";

const items = [
  {
    imagePaths: [sampleCarousel1],
    title: 'Item 1',
    description: (
      <>
        <Typography variant="body1">Sample Size</Typography>
        <Typography variant="body2">Sample size is an important factor to consider when conducting sentiment analysis because it can affect the accuracy and reliability of the results. Sentiment analysis is a statistical technique that involves analyzing a large volume of data to identify patterns and trends in the sentiment or opinion of individuals towards a particular topic or product.</Typography>
      </>
    ),
  },
  {
    imagePaths: [sampleCarousel1],
    title: 'Item 2',
    description: (
      <>
        <Typography variant="body1">What is Average Sentiment</Typography>
        <Typography variant="body2">The sample size is a term used in market research for defining the number of subjects included in a sample size. By sample size, we understand a group of subjects that are selected from the general population and is considered a representative of the real population for that specific study.</Typography>
        <Typography variant="body2" mt={1}>For example, if we want to predict how the population in a specific age group will react to a new product, we can first test it on a sample size that is representative of the targeted population. The sample size, in this case, will be given by the number of people in that age group that will be surveyed.</Typography>
      </>
    ),
  },
];

const SplashScreen = () => {
  const navigate = useNavigate();
  const [isHidden, setIsHidden] = useState(true);
  const [refetchCycle, setRefetchCycle] = useState(true);
 const {
    data: userInfo,
  } = useSampleSizeData({
    enableRefetch:refetchCycle
  });

  useEffect(() => {
    if (userInfo === undefined) {
      setRefetchCycle(true);
    } else {
      setRefetchCycle(false);
      navigate("/login");
    }
  }, [userInfo])

  const toggleCarusel = () => {
    setIsHidden(!isHidden);
  };

  const toggleVisibility = () => {
    setIsHidden(!isHidden);
  };


  const renderItem = (item, index) => {
    return (
      <Paper key={index}>
       <img src={item.imagePaths} alt=""></img>
        <h2>{item.title}</h2>
        <p>{item.description}</p>
      </Paper>
    );
  };

    return(
        <>
        <Container maxWidth="xl" sx={{ position: "relative" }}>
        <Box sx={{ display: "flex", flexDirection: "row", justifyContent: 'space-between', alignItems: "flex-start", gap:"3rem", marginY:"3rem"}}>
          <Box sx={{ width: "65%", display: "flex", alignItems:"center", flexDirection: "column", justifyContent: "flex-start", marginTop:"70px" }} pb={4}>
          <img width="65" height="34" src={logoSplash} alt="Aclis"></img>
<Box mt={3}><img width="80" height="30" src={aclisSplash} mt={3} alt="Aclis"></img></Box>
        <Box class="custom-loader"></Box>
            <Typography variant="h2" mt={3} fontWeight={500} textAlign="center">
            Your sentiment analysis report is in progress.
            </Typography>
            <Typography variant="Subtitle2" mt={2} fontWeight={500} textAlign="center">
            Aclis is processing your sentiment analysis report. This may take several hours to process the data. In the meanwhile, you can analyse our sample report dashboard.
            </Typography>
            </Box>


            <Box px={2} py={3} sx={{ width: "35%", display: "flex", flexDirection: "column", justifyContent: "flex-start", backgroundColor:"#FFFFFF", borderLeft :"1px solid #D2D2D6" }}>
            {!isHidden && (
              <Box>
              <CloseIcon onClick={toggleCarusel}></CloseIcon>
        <Carousel
        autoPlay={false}
        animation="slide"
        indicators={true}
        timeout={500}
        navButtonsAlwaysVisible={true}
        navButtonsProps={{
          style: {
            backgroundColor: 'transparent',
            color: 'black',
            borderRadius: 0,
            margin: 0,
          },
        }}
      >
        {items.map((item, index) => renderItem(item, index))}
      </Carousel>
      </Box>
      )}
      {isHidden && (             
 

<Box>
            <Box textAlign="center">
            <img width="435" height="269" src={analyseSampleReport} alt="Aclis"></img>
            </Box>
            <Typography variant="h6" mt={2} fontWeight={500} textAlign="left">
            Analyse the sample report
            </Typography>
            <Typography variant="Body2" mt={2} fontWeight={300} textAlign="left">
            Add a description for the sample report
            </Typography>
            <Typography variant="Body2" mt={5} fontWeight={700} textAlign="left">
            What’s in the sample report?
            </Typography>
            <Box>
        <ul class="listStyle">
      <li>Sample size</li>
      <li>Average Sentiment</li>
      <li>NPS Score</li>
      <li>Sentiment score by region</li>
      <li>NPS Score</li>
      <li>CSAT Score</li>
      <li>Word Cloud</li>
      <li>Sentiment trend</li>
      </ul>

      </Box>

      <Button variant="contained" color="secondary" onClick={toggleVisibility}>
            Guide me through
</Button>

           </Box>
           )}

          </Box>
            </Box>  

        </Container>
        </>
    );
}; 
export {SplashScreen};