import {Box, Typography } from "@mui/material";
import React from "react";
import ReactDOM from "react-dom";
const Metrix = () => {
  return (
    <>
      <Box mt={5} mb={5} sx={{display: 'flex', flexDirection: 'column',alignItems: 'flex-start', justifyContent:'flex-start',  gap: .25,  width:'50%', borderBottom: 5, paddingBottom:"1rem"}}>
       <Typography variant="h2" fontWeight={600} paddingBottom="2.5rem">
          CX Metrix
        </Typography> 
        <Typography variant="h2" fontWeight={400} paddingBottom="2.5rem">
            An inherently lower CX maturity level not only portrays  &lt;Company Name&gt; abhorrently, but also barres users from utilizing core financial services digitally in an electronic world; costing millions in revenue.
        </Typography>
        <Typography variant="h2" fontWeight={400} paddingBottom="2.5rem">
            Turn towards your customers before they turn away from you. 
        </Typography>
        <Typography variant="h2" fontWeight={400}>
            Get ahead of their concerns. 
        </Typography>
      </Box>
    </>
  );
};

export default Metrix;
 