import React, { useEffect, useState } from "react";
import { animated, useSpring } from "react-spring";
import { useNavigate, useLocation } from "react-router-dom";
import { Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { addYears } from "../../Store/slices/UserSlice";
import { Box, Container, Snackbar } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import MuiAlert from "@mui/material/Alert";
import { Link } from "react-router-dom";
import { Loader } from "../../shared/Loader/Loader";
import { useFetchAllYearData } from "../../hooks/chartHook";
import { Analytics } from "./Analytics";
import { AnalyticsKpi } from "./AnalyticsKpi";
import { Painpoint } from "./Painpoint";
import SVG from "react-inlinesvg";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import iconSunrise from "../assets/images/icon-sunrise.svg";
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 180,
    },
  },
};

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const months = [
  { name: "Jan", value: 1 },
  { name: "Feb", value: 2 },
  { name: "Mar", value: 3 },
  { name: "Apr", value: 4 },
  { name: "May", value: 5 },
  { name: "Jun", value: 6 },
  { name: "Jul", value: 7 },
  { name: "Aug", value: 8 },
  { name: "Sep", value: 9 },
  { name: "Oct", value: 10 },
  { name: "Nov", value: 11 },
  { name: "Dec", value: 12 },
];
const SocialSentimentView = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const [year, setYear] = useState([]);
  const [selectedMonths, setSelectedMonths] = useState([]);
  const {
    data: getYearDetail,
    isLoading: isLoadingDashboard,
    error: apiError,
  } = useFetchAllYearData({
    year: year,
    month: selectedMonths,
  });

  const loggedInUserInfo = useSelector((state) => {
    return state.yearSelector;
  });

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    const yearInNumbers = value
      .filter((option) => option !== "All")
      .map((yearString) => parseInt(yearString, 10));
    setYear(yearInNumbers);
  };
  const getMonthsData = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedMonths(value);
  };

  useEffect(() => {
    dispatch(addYears(year));
  }, [dispatch, year]);

  const navigate = useNavigate();
  const singUpPageView = () => {
    navigate("/registration");
  };
  const [showDiv, setShowDiv] = React.useState(false);
  const fadeIn = useSpring({
    opacity: showDiv ? 1 : 0,
    from: { opacity: 0 },
    config: { duration: 500 }, // Adjust the duration as needed
  });
  setTimeout(() => {
    setShowDiv(true);
  }, 4000); // Delay of 2 seconds

  const handleAllCheckboxChange = (event) => {
    if (event.target.checked) {
      setYear(
        getYearDetail?.yearListDropdown
          ?.filter((option) => option !== "All")
          .map((option) => parseInt(option, 10))
      );
    } else {
      setYear([]);
    }
  };

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={apiError}
        autoHideDuration={6000}
      >
        <Alert severity="error" sx={{ width: "100%" }}>
          {
            '"Oops! An error occurred. Please check your internet connection and try again. If the problem persists, please refresh the page.'
          }
        </Alert>
      </Snackbar>
      {isLoadingDashboard || getYearDetail === undefined ? (
        <Box maxWidth="xl">
          <Loader></Loader>
        </Box>
      ) : (
        <>
          <Container
            maxWidth="xl"
            sx={{
              position: "relative",
              display: "flex",
              flexDirection: "column",
              gap: "1rem",
            }}
          >
            {location.pathname.split("/").includes("demopage") ? (
              <animated.div style={fadeIn}>
                <Box
                  sx={{ background: "rgb(160, 33, 32)", width: "100%" }}
                  mb={2}
                >
                  <Box
                    textAlign="center"
                    p={1}
                    sx={{
                      a: {
                        textDecoration: "none",
                        "&:hover": {
                          cursor: "pointer",
                          textDecoration: "underline",
                        },
                      },
                      "&:hover": {
                        cursor: "pointer",
                      },
                    }}
                    onClick={singUpPageView}
                  >
                    <Link>
                      <Typography sx={{}} color="#fff" variant="Body1">
                        Click here to get a{" "}
                        <strong>tailored realtime dashboard</strong> of your own
                        app
                      </Typography>
                    </Link>
                  </Box>
                </Box>
              </animated.div>
            ) : (
              ""
            )}

            <Box sx={{ position: "relative" }}>
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <div class="strip-bar">
                  <div class="left-side">
                    <Box display="inline-flex" alignItems="center" gap="0.5rem">
                      {" "}
                      <SVG src={iconSunrise} alt="" />{" "}
                      <h2>Good morning, {loggedInUserInfo?.userInfo?.name}</h2>
                    </Box>
                  </div>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "1rem",
                      "& :before,& :after": {
                        border: "0px none!important",
                        background: "transparent!important",
                      },
                      ".MuiInputLabel-root": {
                        fontSize: "0.875rem",
                        color: "#3A3A44",
                        top: "-10px",
                        background: "#fff",
                        padding: "0.25rem 0.5rem 0",
                        borderRadius: "0.5rem 0.5rem 0 0 ",
                        "&.Mui-disabled": {
                          background: "#F0F0F1!important",
                          color: "#9696A0",
                          "&:hover": {
                            background: "#F0F0F1!important",
                            color: "#9696A0",
                          },
                        },
                        "&.MuiInputLabel-shrink": {
                          top: "-18px",
                          padding: "0.125rem 0.5rem 0",
                          fontWeight: "500",
                          color: "#000",
                        },
                      },
                      ".MuiInputBase-formControl": {
                        background: "transparent",
                      },
                      ".MuiSelect-filled,.MuiSelect-root": {
                        fontSize: "1rem",
                        border: "1px solid #ccc",
                        borderRadius: "8px",
                        background: "#fff",
                        padding: "0.125rem 1rem",
                        height: "1.875rem",
                        "&:hover,&:focus": {
                          background: "#fff",
                          borderRadius: "8px",
                        },
                        "&.Mui-disabled": {
                          borderRadius: "8px",
                          background: "#F0F0F1!important",
                          color: "#ccc!important",
                          svg: {
                            color: "#B4B4BB",
                            path: {
                              fill: "#B4B4BB",
                            },
                          },
                          "&:hover": {
                            background: "#F0F0F1!important",
                            svg: {
                              color: "#B4B4BB",
                              path: {
                                fill: "#B4B4BB",
                              },
                            },
                          },
                        },
                      },
                      "& :before,&:before,&:hover :before,,&:active:beforem,& :after,&:after,&:hover :after,,&:active:after":
                        {
                          border: "0px none!important",
                          background: "transparent!important",
                          color: "#3A3A44",
                          borderRadius: "0.25rem",
                        },
                      ".MuiSelect-iconFilled": {
                        width: "1.125rem",
                        height: "1.125rem",
                        color: "#3A3A44",
                        top: "calc(50% - 0.4em)",
                        transform: "rotate(0deg)!important",
                      },
                    }}
                    className="right-side"
                  >
                    <Box sx={{}} display="inline-flex" width="10rem">
                      <FormControl fullWidth size="small" variant="filled">
                        <InputLabel id="year-select-label">All year</InputLabel>
                        <Select
                          labelId="demo-multiple-checkbox-label"
                          id="demo-multiple-checkbox"
                          multiple
                          value={year}
                          label="All year"
                          onChange={handleChange}
                          renderValue={(selected) => selected.join(", ")}
                          MenuProps={MenuProps}
                          IconComponent={CalendarTodayOutlinedIcon}
                          sx={{
                            "&:hover": {
                              background: "transparent",
                              svg: {
                                color: "#A91E1D",
                                path: {
                                  fill: "#A91E1D",
                                },
                              },
                            },
                          }}
                        >
                          <MenuItem key="all" value="All">
                            <Checkbox
                              checked={
                                year.length ===
                                getYearDetail?.yearListDropdown.filter(
                                  (option) => option !== "All"
                                ).length
                              }
                              indeterminate={
                                year.length > 0 &&
                                year.length <
                                  getYearDetail?.yearListDropdown.filter(
                                    (option) => option !== "All"
                                  ).length
                              }
                              onChange={handleAllCheckboxChange}
                              inputProps={{ "aria-label": "select all" }}
                            />
                            <ListItemText primary="All" />
                          </MenuItem>
                          {getYearDetail?.yearListDropdown.map(
                            (name) =>
                              name !== "All" && (
                                <MenuItem
                                  sx={{
                                    "&.MuiMenuItem-root": {
                                      padding: "0.125rem 0.5rem!important",
                                      gap: "0.25rem",
                                      fontWeight: "500",
                                      ".MuiCheckbox-root": {
                                        padding: "5px",
                                        "&.Mui-checked": {
                                          svg: {
                                            color: "#A91E1D",
                                            path: {
                                              fill: "#A91E1D",
                                            },
                                          },
                                        },
                                      },
                                    },
                                  }}
                                  key={name}
                                  value={parseInt(name)}
                                >
                                  <Checkbox
                                    checked={year.includes(parseInt(name))}
                                  />
                                  <ListItemText
                                    style={{ flex: "0" }}
                                    primary={name}
                                  />
                                </MenuItem>
                              )
                          )}
                        </Select>
                      </FormControl>
                    </Box>
                    <Box display="inline-flex" width="12rem">
                      <FormControl fullWidth size="small" variant="filled">
                        <InputLabel
                          disabled
                          sx={{
                            "&.Mui-disabled": {
                              color: "#B4B4BB",
                            },
                          }}
                          id="month-select-label"
                        >
                          Select Months
                        </InputLabel>
                        <Select
                          disabled
                          labelId="month-select-label"
                          id="month-select"
                          multiple
                          value={selectedMonths}
                          onChange={getMonthsData}
                          renderValue={(selected) => selected.join(", ")}
                          IconComponent={CalendarTodayOutlinedIcon}
                          sx={{
                            "&.Mui-disabled": {
                              borderRadius: "8px",
                              background: "#F0F0F1!important",
                              color: "#ccc!important",
                              svg: {
                                color: "#B4B4BB",
                                path: {
                                  fill: "#B4B4BB",
                                },
                              },
                              "&:hover": {
                                background: "#F0F0F1!important",
                                svg: {
                                  color: "#B4B4BB",
                                  path: {
                                    fill: "#B4B4BB",
                                  },
                                },
                              },
                            },
                            "&:hover": {
                              background: "transparent",
                              svg: {
                                color: "#A91E1D",
                                path: {
                                  fill: "#A91E1D",
                                },
                              },
                            },
                          }}
                        >
                          {months.map((month) => (
                            <MenuItem
                              sx={{
                                "&.MuiMenuItem-root": {
                                  gap: "0.25rem",
                                  padding: "0.125rem 0.5rem!important",
                                  fontWeight: "500",
                                  ".MuiCheckbox-root": {
                                    padding: "5px",
                                    "&.Mui-checked": {
                                      svg: {
                                        color: "#A91E1D",
                                        path: {
                                          fill: "#A91E1D",
                                        },
                                      },
                                    },
                                  },
                                },
                              }}
                              key={month.value}
                              value={month.value.toString()}
                            >
                              <Checkbox
                                checked={
                                  selectedMonths.indexOf(
                                    month.value.toString()
                                  ) > -1
                                }
                              />
                              <ListItemText
                                style={{ flex: "0" }}
                                primary={month.name}
                              />
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Box>
                  </Box>
                </div>
              </Box>
            </Box>
            <Box>
              <AnalyticsKpi dataKpi={getYearDetail}></AnalyticsKpi>
            </Box>
            <Box>
              <Painpoint dataKpi={getYearDetail}></Painpoint>
            </Box>
            <Box>
              <Analytics analyticsData={getYearDetail}></Analytics>
            </Box>
            {/* <Box sx={{ position: "relative" }}>
              <h4>Charts</h4>
            </Box> */}
          </Container>
        </>
      )}
    </>
  );
};
export { SocialSentimentView };
