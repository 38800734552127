import "./App.css";
import { Routes, Route, useLocation } from "react-router-dom";
import { Header, Footer } from "./components";
import { DashBoardView } from "./component/DashBoardView/DashBoardView";
import { CompetitiveAnalysis } from "./component/CompetitiveAnalysis/CompetitiveAnalysis";
import { CohortAnalysis } from "./component/CohortAnalysis/CohortAnalysis";
import { HomeView } from "./component/HomeView/HomeView";
import { SocialSentimentView } from "./component/SocialSentimentView/SocialSentimentView";
import { SeeAllSocialSentiment } from "./component/SeeAllSocialSentiment/SeeAllSocialSentiment";
import {
  ProfilePage,
  LoginPage,
  ContactUsPage,
  RegistrationPage,
  DemoPage,
  LandingPage,
  DashboardPage,
  SocialSentiment,
  MutipleDashboardLayout,
  PricingPage,
  DemoSocialSentiment,
  SplashScreen,
  // CohortAnalysis,
} from "./pages";
import PrivateRoute from "./routes/PrivateRoute";

function App() {
  const location = useLocation();
  return (
    <>
      {location.pathname.split("/").includes("dashboard") ||
      location.pathname.split("/").includes("home") ||
      location.pathname.split("/").includes("competitiveanalysis") ||
      location.pathname.split("/").includes("cohortanalysis") ||
      location.pathname.split("/").includes("demopage") ? (
        ""
      ) : (
        <Header />
      )}

      <Routes>
        <Route exact path="/login" element={<LoginPage />}></Route>
        <Route exact path="/splashscreen" element={<SplashScreen />}></Route>
        <Route exact path="/contactus" element={<ContactUsPage />}></Route>
        <Route
          exact
          path="/registration"
          element={<RegistrationPage />}
        ></Route>
        <Route exact path="/pricing" element={<PricingPage />}></Route>
        <Route exact path="/demo" element={<DemoPage />}></Route>
        <Route exact path="/landing" element={<LandingPage />} />
        {/* new design app routing  */}
        <Route
          exact
          path="/home"
          element={
            <PrivateRoute>
              <HomeView />
            </PrivateRoute>
          }
        />
        <Route
          exact
          path="/dashboard"
          element={
            <PrivateRoute>
              <DashBoardView />
            </PrivateRoute>
          }
        >
          <Route
            path="seeAllSocialSentiment"
            element={<SeeAllSocialSentiment />}
          />
          <Route index element={<SocialSentimentView />} />
        </Route>

        <Route
          exact
          path="/competitiveanalysis"
          element={
            <PrivateRoute>
              <CompetitiveAnalysis />
            </PrivateRoute>
          }
        />

        <Route
          exact
          path="/cohortanalysis"
          element={
            <PrivateRoute>
              <CohortAnalysis></CohortAnalysis>
            </PrivateRoute>
          }
        />

        {/* <Route
          exact
          path="/dashboard"
          element={
            <PrivateRoute>
              <DashboardPage />
            </PrivateRoute>
          }
        >
          <Route path="cohort" element={<CohortAnalysis />} />
          <Route path="socialsentiment" element={<SocialSentiment />} />
          <Route path="profilepage" element={<ProfilePage />} />
          <Route index element={<MutipleDashboardLayout />} />
        </Route> */}
        <Route path="/demopage" element={<DemoSocialSentiment />} />
        <Route path="*" element={<LandingPage />} />
      </Routes>
      <div className="App"></div>
      {location.pathname.split("/").includes("demopage") ||
      location.pathname.split("/").includes("dashboard") ||
      location.pathname.split("/").includes("home") ||
      location.pathname.split("/").includes("cohortanalysis") ||
      location.pathname.split("/").includes("competitiveanalysis") ? (
        ""
      ) : (
        <Footer />
      )}
    </>
  );
}

export default App;
