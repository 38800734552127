const validEmailRegex = RegExp(
    // eslint-disable-next-line no-useless-escape
    /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
  );
  const validContactRegex = RegExp(/^[0-9]+$/);
  const strongPasswordRegex = RegExp(
    /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{6,}$/
  );
  function validation(object) {
    var errors = {};
    Object.entries(object).forEach(([key, value]) => {
      var field = { field: key, invalid: false, message: "" };
      var response = validateField(key, value, object);
      if (response !== "") {
        field.invalid = true;
        field.message = response;
      }
      errors[key] = field;
    });
  
    var invalid = false;
    Object.entries(errors).forEach(([key, value]) => {
      if (value.invalid) {
        invalid = true;
      }
    });
    return { errors, invalid };
  }
  
  function validateField(field, value, object) {
    switch (field) {
      case "email":
        if (value.trim() === "") {
          return "Email cannot be empty";
        } else if (!validEmailRegex.test(value)) {
          return "Email is not valid!";
        } else {
          return "";
        }
  
      case "name":
        if (value.trim() === "") {
          return "Name cannot be empty";
        } else {
          return "";
        }

        case "companyname":
        if (value.trim() === "") {
          return "Company Name cannot be empty";
        } else {
          return "";
        }

        case "companytype":
        if (value.trim() === "") {
          return "Company Type cannot be empty";
        } else {
          return "";
        }
  
      case "login_password":
        if (value.trim() === "") {
          return "Password cannot be empty";
        }
        if (value.trim().length < 6) {
          return "Password must be atleast 6 characters long";
        }
        // if (value.trim().length > 20) { return "Your Email ID or Password is Incorrect"; }
        else {
          return "";
        }
  
      case "password":
        if (value.trim() === "") {
          return "Password cannot be empty";
        }
        if (!strongPasswordRegex.test(value)) {
          return "Password must contain atleast 6-20 characters including one lowercase, uppercase, numeric and special character";
        }
        if (value.trim().length < 6) {
          return "Password must be atleast 6 characters long";
        }
        // if (value.trim().length > 20) { return "Password must be maximum 20 characters long"; }
        else {
          return "";
        }
  
      case "confirm_password":
        if (value.trim() === "") {
          return "Confirm Password cannot be empty";
        }
        if (value.trim().length < 6) {
          return "Confirm Password must be atleast 6 characters long";
        }
        // if (value.trim().length > 20) { return "Confirm Password must be maximum 20 characters long"; }
        if (object["password"] && value.trim() !== object["password"]) {
          return "Password Do Not Match";
        } else {
          return "";
        }
  
      case "role":
        if (value.trim() === "") {
          return "Role cannot be empty";
        } else {
          return "";
        }
  
      case "contact":
        if (value.trim() === "") {
          return "Contact cannot be empty";
        } else if (!validContactRegex.test(value)) {
          return "Invalid contact number";
        } else if (value.trim().length < 9 || value.trim().length > 15) {
          return "Contact number must be 9-15 characters long";
        } else {
          return "";
        }
  
      case "organization":
        if (value.trim() === "") {
          return "Organization cannot be empty";
        } else {
          return "";
        }
  
      default:
        return "";
    }
  }
  
  export default validation;