import { React, useEffect } from "react";
import { Box, Grid, Typography, List, ListItem, Button } from "@mui/material";
import { Link, useNavigate  } from 'react-router-dom';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import MoreVertIcon from '@mui/icons-material/MoreVert';

const whiteCardTile = { '&:hover': { cursor: "pointer", boxShadow:"7" },  border: "1px solid #D2D2D6", borderRadius: "10px", background: "#fff", boxShadow: "5",  p:"1.25rem", mb:"0.125rem", width:"23.7%" }; 

const MutipleDashboardLayout = () => {

  let navigate = useNavigate(); 
  const boxStyles = {
    filter: 'blur(1.5px)',
  };
  const routeChange = () =>{ 
    let path = "/dashboard/socialsentiment"; 
    navigate(path);
  }

  useEffect(() => {
    console.log('MULTIPLE DASHBOARD LAYOUT API COMES HERE');
  }, [])
  return (
    <>
      <Box sx={{ background: "#F9F9FA", position:"relative", paddingTop:"3.125rem" }} px={2}>
        {/* <Box sx={{ background: "#5494FF", position:"absolute", width:"103%", top:"-1.437rem", left:"-0.937rem" }} mb={2}>
          <Box textAlign="center" p={1}><Link to={"/registration"}><Typography color="#fff" variant="Body1">Click here to get a <strong>tailored dashboard</strong> of your own app</Typography></Link></Box>
        </Box> */}

        {/* <Box mb={1} display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
          <Typography variant="h6" mb={1}>Home</Typography>
          <Button type="submit" variant="contained" py="2" sx={{ '&:hover': { bgcolor: "#A45959" }, borderRadius: "4px", backgroundColor: "#D87474", padding: "10px 20px" }}>
            Request new dashboard
          </Button>
        </Box> */}
        <Typography variant="button" fontWeight={600}>Bookmarked Dashboard(s)</Typography>
        <Typography mt={1} mb={2} component="div" variant="button">2 <Box component="span" sx={{ color: 'text.disabled' }}>Available</Box></Typography>
        
        
        <Box mb={6} display="flex" flexDirection="row" justifyContent="flex-start" alignItems="center" flexWrap="wrap" gap={3}>
        
          <Box  sx={whiteCardTile} onClick={routeChange}>
            <Box sx={{ display: "flex", flexDirection: "row", justifyContent: 'space-between', alignItems: "flex-start", }}>
              <Box marginRight="auto"><Typography fontWeight={500} variant="button">Social Sentiment</Typography></Box>
              <Box sx={{ display: "flex", flexDirection: "row", justifyContent: 'space-between', alignItems: "flex-start", opacity:"0.5" }} >
                <Box mr={1}><BookmarkIcon /></Box>
                <MoreVertIcon /></Box></Box>
            <Box mb={3} sx={{ display: "flex", flexDirection: "row", justifyContent: 'space-between', alignItems: "center", }}>
              <List sx={{ listStyleType: 'disc', pt: 0, pl: 2,
                '& .MuiListItem-root': {
                  display: 'list-item',
                  paddingBottom: "0",
                },
              }}>
                <ListItem style={{ paddingLeft: "0" }}>
                  <Typography fontWeight={400} variant="body2" display="block" sx={{ color: "#9696A0" }}>Sentiments Scores by Sources</Typography>
                </ListItem>
                <ListItem style={{ paddingLeft: "0" }}>
                  <Typography fontWeight={400} variant="body2" display="block" sx={{ color: "#9696A0" }}>Sentiments Scores by Sources</Typography>
                </ListItem>
                <ListItem style={{ paddingLeft: "0" }}>
                  <Typography fontWeight={400} variant="body2" display="block" sx={{ color: "#9696A0" }}>CSAT Score</Typography>
                </ListItem>
                <ListItem style={{ paddingLeft: "0" }}>
                  <Typography fontWeight={400} variant="body2" display="block" sx={{ color: "#9696A0" }}>Sentiments Trend</Typography>
                </ListItem>
              </List>
            </Box>
            <Box><Typography variant="caption">Created yesterday</Typography></Box>
          </Box>
      
          <Box style={boxStyles} sx={whiteCardTile}>
            <Box sx={{ display: "flex", flexDirection: "row", justifyContent: 'space-between', alignItems: "flex-start", }}>
              <Box marginRight="auto"><Typography fontWeight={500} variant="button">Social Sentiment</Typography></Box>
              <Box sx={{ display: "flex", flexDirection: "row", justifyContent: 'space-between', alignItems: "flex-start", opacity:"0.5" }} >
                <Box mr={1}><BookmarkIcon /></Box>
                <MoreVertIcon /></Box></Box>
            <Box mb={3} sx={{ display: "flex", flexDirection: "row", justifyContent: 'space-between', alignItems: "center", }}>
              <List sx={{ listStyleType: 'disc', pt: 0, pl: 2,
                '& .MuiListItem-root': {
                  display: 'list-item',
                  paddingBottom: "0",
                },
              }}>
                <ListItem style={{ paddingLeft: "0" }}>
                  <Typography fontWeight={400} variant="body2" display="block" sx={{ color: "#9696A0" }}>Sentiments Scores by Sources</Typography>
                </ListItem>
                <ListItem style={{ paddingLeft: "0" }}>
                  <Typography fontWeight={400} variant="body2" display="block" sx={{ color: "#9696A0" }}>Sentiments Scores by Sources</Typography>
                </ListItem>
                <ListItem style={{ paddingLeft: "0" }}>
                  <Typography fontWeight={400} variant="body2" display="block" sx={{ color: "#9696A0" }}>CSAT Score</Typography>
                </ListItem>
                <ListItem style={{ paddingLeft: "0" }}>
                  <Typography fontWeight={400} variant="body2" display="block" sx={{ color: "#9696A0" }}>Sentiments Trend</Typography>
                </ListItem>
              </List>
            </Box>
            <Box><Typography variant="caption">Created yesterday</Typography></Box>
          </Box>
        </Box>


        <Typography variant="button" fontWeight={600}>All Dashboard(s)</Typography>
        <Typography mt={1} mb={2} component="div" variant="button">6 <Box component="span" sx={{ color: 'text.disabled' }}>Available</Box></Typography>
        <Box mb={1} display="flex" flexDirection="row" justifyContent="flex-start" alignItems="center" flexWrap="wrap" gap={3}>
          <Box style={boxStyles} sx={whiteCardTile}>
            <Box sx={{ display: "flex", flexDirection: "row", justifyContent: 'space-between', alignItems: "flex-start", }}>
              <Box marginRight="auto"><Typography fontWeight={500} variant="button">Social Sentiment</Typography></Box>
              <Box sx={{ display: "flex", flexDirection: "row", justifyContent: 'space-between', alignItems: "flex-start", opacity:"0.5" }} >
                <Box mr={1}><BookmarkIcon /></Box>
                <MoreVertIcon /></Box></Box>
            <Box mb={3} sx={{ display: "flex", flexDirection: "row", justifyContent: 'space-between', alignItems: "center", }}>
              <List sx={{ listStyleType: 'disc', pt: 0, pl: 2,
                '& .MuiListItem-root': {
                  display: 'list-item',
                  paddingBottom: "0",
                },
              }}>
                <ListItem style={{ paddingLeft: "0" }}>
                  <Typography fontWeight={400} variant="body2" display="block" sx={{ color: "#9696A0" }}>Sentiments Scores by Sources</Typography>
                </ListItem>
                <ListItem style={{ paddingLeft: "0" }}>
                  <Typography fontWeight={400} variant="body2" display="block" sx={{ color: "#9696A0" }}>Sentiments Scores by Sources</Typography>
                </ListItem>
                <ListItem style={{ paddingLeft: "0" }}>
                  <Typography fontWeight={400} variant="body2" display="block" sx={{ color: "#9696A0" }}>CSAT Score</Typography>
                </ListItem>
                <ListItem style={{ paddingLeft: "0" }}>
                  <Typography fontWeight={400} variant="body2" display="block" sx={{ color: "#9696A0" }}>Sentiments Trend</Typography>
                </ListItem>
              </List>
            </Box>
            <Box><Typography variant="caption">Created yesterday</Typography></Box>
          </Box>
          <Box style={boxStyles} sx={whiteCardTile}>
            <Box sx={{ display: "flex", flexDirection: "row", justifyContent: 'space-between', alignItems: "flex-start", }}>
              <Box marginRight="auto"><Typography fontWeight={500} variant="button">Social Sentiment</Typography></Box>
              <Box sx={{ display: "flex", flexDirection: "row", justifyContent: 'space-between', alignItems: "flex-start", opacity:"0.5" }} >
                <Box mr={1}><BookmarkIcon /></Box>
                <MoreVertIcon /></Box></Box>
            <Box mb={3} sx={{ display: "flex", flexDirection: "row", justifyContent: 'space-between', alignItems: "center", }}>
              <List sx={{ listStyleType: 'disc', pt: 0, pl: 2,
                '& .MuiListItem-root': {
                  display: 'list-item',
                  paddingBottom: "0",
                },
              }}>
                <ListItem style={{ paddingLeft: "0" }}>
                  <Typography fontWeight={400} variant="body2" display="block" sx={{ color: "#9696A0" }}>Sentiments Scores by Sources</Typography>
                </ListItem>
                <ListItem style={{ paddingLeft: "0" }}>
                  <Typography fontWeight={400} variant="body2" display="block" sx={{ color: "#9696A0" }}>Sentiments Scores by Sources</Typography>
                </ListItem>
                <ListItem style={{ paddingLeft: "0" }}>
                  <Typography fontWeight={400} variant="body2" display="block" sx={{ color: "#9696A0" }}>CSAT Score</Typography>
                </ListItem>
                <ListItem style={{ paddingLeft: "0" }}>
                  <Typography fontWeight={400} variant="body2" display="block" sx={{ color: "#9696A0" }}>Sentiments Trend</Typography>
                </ListItem>
              </List>
            </Box>
            <Box><Typography variant="caption">Created yesterday</Typography></Box>
          </Box>
          <Box style={boxStyles} sx={whiteCardTile}>
            <Box sx={{ display: "flex", flexDirection: "row", justifyContent: 'space-between', alignItems: "flex-start", }}>
              <Box marginRight="auto"><Typography fontWeight={500} variant="button">Social Sentiment</Typography></Box>
              <Box sx={{ display: "flex", flexDirection: "row", justifyContent: 'space-between', alignItems: "flex-start", opacity:"0.5" }} >
                <Box mr={1}><BookmarkIcon /></Box>
                <MoreVertIcon /></Box></Box>
            <Box mb={3} sx={{ display: "flex", flexDirection: "row", justifyContent: 'space-between', alignItems: "center", }}>
              <List sx={{ listStyleType: 'disc', pt: 0, pl: 2,
                '& .MuiListItem-root': {
                  display: 'list-item',
                  paddingBottom: "0",
                },
              }}>
                <ListItem style={{ paddingLeft: "0" }}>
                  <Typography fontWeight={400} variant="body2" display="block" sx={{ color: "#9696A0" }}>Sentiments Scores by Sources</Typography>
                </ListItem>
                <ListItem style={{ paddingLeft: "0" }}>
                  <Typography fontWeight={400} variant="body2" display="block" sx={{ color: "#9696A0" }}>Sentiments Scores by Sources</Typography>
                </ListItem>
                <ListItem style={{ paddingLeft: "0" }}>
                  <Typography fontWeight={400} variant="body2" display="block" sx={{ color: "#9696A0" }}>CSAT Score</Typography>
                </ListItem>
                <ListItem style={{ paddingLeft: "0" }}>
                  <Typography fontWeight={400} variant="body2" display="block" sx={{ color: "#9696A0" }}>Sentiments Trend</Typography>
                </ListItem>
              </List>
            </Box>
            <Box><Typography variant="caption">Created yesterday</Typography></Box>
          </Box>
          <Box style={boxStyles} sx={whiteCardTile}>
            <Box sx={{ display: "flex", flexDirection: "row", justifyContent: 'space-between', alignItems: "flex-start", }}>
              <Box marginRight="auto"><Typography fontWeight={500} variant="button">Social Sentiment</Typography></Box>
              <Box sx={{ display: "flex", flexDirection: "row", justifyContent: 'space-between', alignItems: "flex-start", opacity:"0.5" }} >
                <Box mr={1}><BookmarkIcon /></Box>
                <MoreVertIcon /></Box></Box>
            <Box mb={3} sx={{ display: "flex", flexDirection: "row", justifyContent: 'space-between', alignItems: "center", }}>
              <List sx={{ listStyleType: 'disc', pt: 0, pl: 2,
                '& .MuiListItem-root': {
                  display: 'list-item',
                  paddingBottom: "0",
                },
              }}>
                <ListItem style={{ paddingLeft: "0" }}>
                  <Typography fontWeight={400} variant="body2" display="block" sx={{ color: "#9696A0" }}>Sentiments Scores by Sources</Typography>
                </ListItem>
                <ListItem style={{ paddingLeft: "0" }}>
                  <Typography fontWeight={400} variant="body2" display="block" sx={{ color: "#9696A0" }}>Sentiments Scores by Sources</Typography>
                </ListItem>
                <ListItem style={{ paddingLeft: "0" }}>
                  <Typography fontWeight={400} variant="body2" display="block" sx={{ color: "#9696A0" }}>CSAT Score</Typography>
                </ListItem>
                <ListItem style={{ paddingLeft: "0" }}>
                  <Typography fontWeight={400} variant="body2" display="block" sx={{ color: "#9696A0" }}>Sentiments Trend</Typography>
                </ListItem>
              </List>
            </Box>
            <Box><Typography variant="caption">Created yesterday</Typography></Box>
          </Box>
          <Box style={boxStyles} sx={whiteCardTile}>
            <Box sx={{ display: "flex", flexDirection: "row", justifyContent: 'space-between', alignItems: "flex-start", }}>
              <Box marginRight="auto"><Typography fontWeight={500} variant="button">Social Sentiment</Typography></Box>
              <Box sx={{ display: "flex", flexDirection: "row", justifyContent: 'space-between', alignItems: "flex-start", opacity:"0.5" }} >
                <Box mr={1}><BookmarkIcon /></Box>
                <MoreVertIcon /></Box></Box>
            <Box mb={3} sx={{ display: "flex", flexDirection: "row", justifyContent: 'space-between', alignItems: "center", }}>
              <List sx={{ listStyleType: 'disc', pt: 0, pl: 2,
                '& .MuiListItem-root': {
                  display: 'list-item',
                  paddingBottom: "0",
                },
              }}>
                <ListItem style={{ paddingLeft: "0" }}>
                  <Typography fontWeight={400} variant="body2" display="block" sx={{ color: "#9696A0" }}>Sentiments Scores by Sources</Typography>
                </ListItem>
                <ListItem style={{ paddingLeft: "0" }}>
                  <Typography fontWeight={400} variant="body2" display="block" sx={{ color: "#9696A0" }}>Sentiments Scores by Sources</Typography>
                </ListItem>
                <ListItem style={{ paddingLeft: "0" }}>
                  <Typography fontWeight={400} variant="body2" display="block" sx={{ color: "#9696A0" }}>CSAT Score</Typography>
                </ListItem>
                <ListItem style={{ paddingLeft: "0" }}>
                  <Typography fontWeight={400} variant="body2" display="block" sx={{ color: "#9696A0" }}>Sentiments Trend</Typography>
                </ListItem>
              </List>
            </Box>
            <Box><Typography variant="caption">Created yesterday</Typography></Box>
          </Box>
          <Box style={boxStyles} sx={whiteCardTile}>
            <Box sx={{ display: "flex", flexDirection: "row", justifyContent: 'space-between', alignItems: "flex-start", }}>
              <Box marginRight="auto"><Typography fontWeight={500} variant="button">Social Sentiment</Typography></Box>
              <Box sx={{ display: "flex", flexDirection: "row", justifyContent: 'space-between', alignItems: "flex-start", opacity:"0.5" }} >
                <Box mr={1}><BookmarkIcon /></Box>
                <MoreVertIcon /></Box></Box>
            <Box mb={3} sx={{ display: "flex", flexDirection: "row", justifyContent: 'space-between', alignItems: "center", }}>
              <List sx={{ listStyleType: 'disc', pt: 0, pl: 2,
                '& .MuiListItem-root': {
                  display: 'list-item',
                  paddingBottom: "0",
                },
              }}>
                <ListItem style={{ paddingLeft: "0" }}>
                  <Typography fontWeight={400} variant="body2" display="block" sx={{ color: "#9696A0" }}>Sentiments Scores by Sources</Typography>
                </ListItem>
                <ListItem style={{ paddingLeft: "0" }}>
                  <Typography fontWeight={400} variant="body2" display="block" sx={{ color: "#9696A0" }}>Sentiments Scores by Sources</Typography>
                </ListItem>
                <ListItem style={{ paddingLeft: "0" }}>
                  <Typography fontWeight={400} variant="body2" display="block" sx={{ color: "#9696A0" }}>CSAT Score</Typography>
                </ListItem>
                <ListItem style={{ paddingLeft: "0" }}>
                  <Typography fontWeight={400} variant="body2" display="block" sx={{ color: "#9696A0" }}>Sentiments Trend</Typography>
                </ListItem>
              </List>
            </Box>
            <Box><Typography variant="caption">Created yesterday</Typography></Box>
          </Box>
        </Box>

        {/* <Grid container spacing={2}>
          <Grid item xs={12}>
            
            
            <Grid container spacing={2} mb={2}>
              <Grid item xs={3}>
                <Box sx={{ border: "1px solid #D2D2D6", borderRadius: "10px", background: "#fff", boxShadow: "5" }} p={3} mb={2}>
                  <Box sx={{ display: "flex", flexDirection: "row", justifyContent: 'space-between', alignItems: "flex-start", }}>
                    <Box marginRight="auto"><Typography fontWeight={500} variant="button">Social Sentiment</Typography></Box>
                    <Box sx={{ display: "flex", flexDirection: "row", justifyContent: 'space-between', alignItems: "flex-start", }} >
                      <Box mr={1}><BookmarkIcon /></Box>
                      <MoreVertIcon /></Box></Box>
                  <Box mb={3} sx={{ display: "flex", flexDirection: "row", justifyContent: 'space-between', alignItems: "center", }}>
                    <List sx={{
                      listStyleType: 'disc',
                      pt: 0,
                      pl: 2,
                      '& .MuiListItem-root': {
                        display: 'list-item',
                        paddingBottom: "0",
                      },
                    }}>
                      <ListItem style={{ paddingLeft: "0" }}>
                        <Typography fontWeight={400} variant="body2" display="block" sx={{ color: "#9696A0" }}>Sentiments Scores by Sources</Typography>
                      </ListItem>
                      <ListItem style={{ paddingLeft: "0" }}>
                        <Typography fontWeight={400} variant="body2" display="block" sx={{ color: "#9696A0" }}>Sentiments Scores by Sources</Typography>
                      </ListItem>
                      <ListItem style={{ paddingLeft: "0" }}>
                        <Typography fontWeight={400} variant="body2" display="block" sx={{ color: "#9696A0" }}>CSAT Score</Typography>
                      </ListItem>
                      <ListItem style={{ paddingLeft: "0" }}>
                        <Typography fontWeight={400} variant="body2" display="block" sx={{ color: "#9696A0" }}>Sentiments Trend</Typography>
                      </ListItem>
                    </List>
                  </Box>
                  <Box><Typography variant="caption">Created yesterday</Typography></Box>
                </Box>

              </Grid>
              <Grid item xs={3}>
                <Box sx={{ border: "1px solid #D2D2D6", borderRadius: "10px", background: "#fff", boxShadow: "5" }} p={3} mb={2}>
                  <Box sx={{ display: "flex", flexDirection: "row", justifyContent: 'space-between', alignItems: "flex-start", }}>
                    <Box marginRight="auto"><Typography fontWeight={500} variant="button">NPS Sentiment</Typography></Box>
                    <Box sx={{ display: "flex", flexDirection: "row", justifyContent: 'space-between', alignItems: "flex-start", }} >
                      <Box mr={1}><BookmarkIcon /></Box>
                      <MoreVertIcon /></Box></Box>
                  <Box mb={3} sx={{ display: "flex", flexDirection: "row", justifyContent: 'space-between', alignItems: "center", }}>
                    <List sx={{
                      listStyleType: 'disc',
                      pt: 0,
                      pl: 2,
                      '& .MuiListItem-root': {
                        display: 'list-item',
                        paddingBottom: "0",
                      },
                    }}>
                      <ListItem style={{ paddingLeft: "0" }}>
                        <Typography fontWeight={400} variant="body2" display="block" sx={{ color: "#9696A0" }}>Statistics and overview</Typography>
                      </ListItem>
                      <ListItem style={{ paddingLeft: "0" }}>
                        <Typography fontWeight={400} variant="body2" display="block" sx={{ color: "#9696A0" }}>Website Concept</Typography>
                      </ListItem>
                      <ListItem style={{ paddingLeft: "0" }}>
                        <Typography fontWeight={400} variant="body2" display="block" sx={{ color: "#9696A0" }}>All marketing Concepts</Typography>
                      </ListItem>
                      <ListItem style={{ paddingLeft: "0" }}>
                        <Typography fontWeight={400} variant="body2" display="block" sx={{ color: "#9696A0" }}>Shallenges and tests</Typography>
                      </ListItem>
                    </List>
                  </Box>
                  <Box><Typography variant="caption">Created yesterday</Typography></Box>
                </Box>

              </Grid>
              <Grid item xs={3}>
                <Box sx={{ border: "1px solid #D2D2D6", borderRadius: "10px", background: "#fff", boxShadow: "5" }} p={3} mb={2}>
                  <Box sx={{ display: "flex", flexDirection: "row", justifyContent: 'space-between', alignItems: "flex-start", }}>
                    <Box marginRight="auto"><Typography fontWeight={500} variant="button">Community health</Typography></Box>
                    <Box sx={{ display: "flex", flexDirection: "row", justifyContent: 'space-between', alignItems: "flex-start", }} >
                      <Box mr={1}><BookmarkIcon /></Box>
                      <MoreVertIcon /></Box></Box>
                  <Box mb={3} sx={{ display: "flex", flexDirection: "row", justifyContent: 'space-between', alignItems: "center", }}>
                    <Box py={4}><Typography variant="button" sx={{ color: 'text.disabled' }}>Check if community is healthy and growing
                      and review the most impactful channels with their insights</Typography></Box>
                  </Box>
                  <Box><Typography variant="caption" sx={{ color: 'text.disabled' }}>Created yesterday</Typography></Box>
                </Box>

              </Grid>
            </Grid>
            <Box mb={1}>
              <Typography variant="button" fontWeight={600}>All Dashboard(s)</Typography>
              <Typography mt={1} component="div" variant="button">6 <Box component="span" sx={{ color: 'text.disabled' }}>Available</Box></Typography>
            </Box>
            <Grid container spacing={2}>
              <Grid item xs={3}>
                <Box sx={{ border: "1px solid #D2D2D6", borderRadius: "10px", background: "#fff", boxShadow: "5" }} p={3} mb={2}>
                  <Box sx={{ display: "flex", flexDirection: "row", justifyContent: 'space-between', alignItems: "flex-start", }}>
                    <Box marginRight="auto"><Typography fontWeight={500} variant="button">Social Media</Typography></Box>
                    <Box sx={{ display: "flex", flexDirection: "row", justifyContent: 'space-between', alignItems: "flex-start", }} >
                      <Box mr={1}><BookmarkIcon /></Box>
                      <MoreVertIcon /></Box></Box>
                  <Box mb={3} sx={{ display: "flex", flexDirection: "row", justifyContent: 'space-between', alignItems: "center", }}>
                    <List sx={{
                      listStyleType: 'disc',
                      pt: 0,
                      pl: 2,
                      '& .MuiListItem-root': {
                        display: 'list-item',
                        paddingBottom: "0",
                      },
                    }}>
                      <ListItem style={{ paddingLeft: "0" }}>
                        <Typography fontWeight={400} variant="body2" display="block" sx={{ color: "#9696A0" }}>Sentiments Scores by Sources</Typography>
                      </ListItem>
                      <ListItem style={{ paddingLeft: "0" }}>
                        <Typography fontWeight={400} variant="body2" display="block" sx={{ color: "#9696A0" }}>Sentiments Scores by Sources</Typography>
                      </ListItem>
                      <ListItem style={{ paddingLeft: "0" }}>
                        <Typography fontWeight={400} variant="body2" display="block" sx={{ color: "#9696A0" }}>CSAT Score</Typography>
                      </ListItem>
                      <ListItem style={{ paddingLeft: "0" }}>
                        <Typography fontWeight={400} variant="body2" display="block" sx={{ color: "#9696A0" }}>Sentiments Trend</Typography>
                      </ListItem>
                    </List>
                  </Box>
                  <Box><Typography variant="caption">Created yesterday</Typography></Box>
                </Box>

              </Grid>
              <Grid item xs={3}>
                <Box sx={{ border: "1px solid #D2D2D6", borderRadius: "10px", background: "#fff", boxShadow: "5" }} p={3} mb={2}>
                  <Box sx={{ display: "flex", flexDirection: "row", justifyContent: 'space-between', alignItems: "flex-start", }}>
                    <Box marginRight="auto"><Typography fontWeight={500} variant="button">Cohort Analysis</Typography></Box>
                    <Box sx={{ display: "flex", flexDirection: "row", justifyContent: 'space-between', alignItems: "flex-start", }} >
                      <Box mr={1}><BookmarkIcon /></Box>
                      <MoreVertIcon /></Box></Box>
                  <Box mb={3} sx={{ display: "flex", flexDirection: "row", justifyContent: 'space-between', alignItems: "center", }}>
                    <List sx={{
                      listStyleType: 'disc',
                      pt: 0,
                      pl: 2,
                      '& .MuiListItem-root': {
                        display: 'list-item',
                        paddingBottom: "0",
                      },
                    }}>
                      <ListItem style={{ paddingLeft: "0" }}>
                        <Typography fontWeight={400} variant="body2" display="block" sx={{ color: "#9696A0" }}>Sentiments Scores by Sources</Typography>
                      </ListItem>
                      <ListItem style={{ paddingLeft: "0" }}>
                        <Typography fontWeight={400} variant="body2" display="block" sx={{ color: "#9696A0" }}>Sentiments Scores by Sources</Typography>
                      </ListItem>
                      <ListItem style={{ paddingLeft: "0" }}>
                        <Typography fontWeight={400} variant="body2" display="block" sx={{ color: "#9696A0" }}>CSAT Score</Typography>
                      </ListItem>
                      <ListItem style={{ paddingLeft: "0" }}>
                        <Typography fontWeight={400} variant="body2" display="block" sx={{ color: "#9696A0" }}>Sentiments Trend</Typography>
                      </ListItem>
                    </List>
                  </Box>
                  <Box><Typography variant="caption">Created yesterday</Typography></Box>
                </Box>

              </Grid>
              <Grid item xs={3}>
                <Box sx={{ border: "1px solid #D2D2D6", borderRadius: "10px", background: "#fff", boxShadow: "5" }} p={3} mb={2}>
                  <Box sx={{ display: "flex", flexDirection: "row", justifyContent: 'space-between', alignItems: "flex-start", }}>
                    <Box marginRight="auto"><Typography fontWeight={500} variant="button">Social Sentiment</Typography></Box>
                    <Box sx={{ display: "flex", flexDirection: "row", justifyContent: 'space-between', alignItems: "flex-start", }} >
                      <Box mr={1}><BookmarkIcon /></Box>
                      <MoreVertIcon /></Box></Box>
                  <Box mb={3} sx={{ display: "flex", flexDirection: "row", justifyContent: 'space-between', alignItems: "center", }}>
                    <List sx={{
                      listStyleType: 'disc',
                      pt: 0,
                      pl: 2,
                      '& .MuiListItem-root': {
                        display: 'list-item',
                        paddingBottom: "0",
                      },
                    }}>
                      <ListItem style={{ paddingLeft: "0" }}>
                        <Typography fontWeight={400} variant="body2" display="block" sx={{ color: "#9696A0" }}>Sentiments Scores by Sources</Typography>
                      </ListItem>
                      <ListItem style={{ paddingLeft: "0" }}>
                        <Typography fontWeight={400} variant="body2" display="block" sx={{ color: "#9696A0" }}>Sentiments Scores by Sources</Typography>
                      </ListItem>
                      <ListItem style={{ paddingLeft: "0" }}>
                        <Typography fontWeight={400} variant="body2" display="block" sx={{ color: "#9696A0" }}>CSAT Score</Typography>
                      </ListItem>
                      <ListItem style={{ paddingLeft: "0" }}>
                        <Typography fontWeight={400} variant="body2" display="block" sx={{ color: "#9696A0" }}>Sentiments Trend</Typography>
                      </ListItem>
                    </List>
                  </Box>
                  <Box><Typography variant="caption">Created yesterday</Typography></Box>
                </Box>

              </Grid>
              <Grid item xs={3} alignSelf="stretch">
                <Box sx={{ border: "1px solid #D2D2D6", borderRadius: "10px", background: "#fff", boxShadow: "5" }} p={3} mb={2}>
                  <Box sx={{ display: "flex", flexDirection: "row", justifyContent: 'space-between', alignItems: "flex-start", }}>
                    <Box marginRight="auto"><Typography fontWeight={500} variant="button">Community health</Typography></Box>
                    <Box sx={{ display: "flex", flexDirection: "row", justifyContent: 'space-between', alignItems: "flex-start", }} >
                      <Box mr={1}><BookmarkIcon /></Box>
                      <MoreVertIcon /></Box></Box>
                  <Box mb={3} sx={{ display: "flex", flexDirection: "row", justifyContent: 'space-between', alignItems: "center", }}>
                    <Box py={4}><Typography variant="button" sx={{ color: 'text.disabled' }}>Check if community is healthy and growing
                      and review the most impactful channels with their insights</Typography></Box>
                  </Box>
                  <Box><Typography variant="caption" sx={{ color: 'text.disabled' }}>Created yesterday</Typography></Box>
                </Box>

              </Grid>
            </Grid>
          </Grid>

        </Grid> */}
      </Box>
    </>
  );
};
export { MutipleDashboardLayout };