import { LayOutView } from "../LayOutView/LayOutView";
const CohortAnalysis = () => {
  return (
    <>
      <LayOutView>
        <div class="responsive-iframe iframe">
          <iframe
            title="Report Section"
            width="400"
            height="373.5"
            src="https://app.powerbi.com/reportEmbed?reportId=af5d5c23-92fc-4e9b-aa22-f37837f740b5&autoAuth=true&ctid=8a1488ad-635a-4fd2-981f-62ab0a31fa1c&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLUJJWTQtUmVkaXJlY3QudXMtZWFzdC0xLmFtYXpvbmF3cy5jb20vIiwib2JqZWN0IjoiY2YwMjBkYzctMjFhMy00NDUzLWFlMTItYmRkMzZmYjA4MzY1Iiwic2VhcmNoVHlwZSI6ImVuY29kaW5nIiwidGl0bGUiOiJSZXBvcnQgU2VjdGlvbiIsImFjY2Vzc1BlcmNlcHRpb25FbmFibGVkIjp0cnVlLCJzZXF1ZW5jZU51bWJlciI6MCwic2VjdGlvbk5hbWUiOiJhcHAtcG9ydGFsIiwicmVzb3VyY2VJZCI6IjQwYmIyMzg4LTk1MjktNDA5Zi1iYjVlLWZmYmRlMTYyMWJlYyIsImFkYXB0aXZlTmFtZSI6ImFuZHJvaWQiLCJpc0FkbWluIjp0cnVlfQ%3D%3D"
            frameborder="0"
            allowFullScreen="true"
          ></iframe>
        </div>
      </LayOutView>
    </>
  );
};
export { CohortAnalysis };
