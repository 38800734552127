import { Box } from "@mui/material";
import "./SocialSentimentView.css";
import SVG from "react-inlinesvg";
import arrowRise from "../assets/images/arrowrise.svg";
import arrowFall from "../assets/images/arrowfall.svg";
const AnalyticsKpi = (props) => {
  const { csatScore, nps, avgSentimentScore } = props?.dataKpi.cxMetrics;
  return (
    <>
      {/* <h1>kpis</h1> */}
      <Box
        sx={{
          display: "flex",
          width: "100%",
          flexWrap: "wrap",
          gap: "1.5rem",
          ".card": {
            flex: "1 0",
            padding: "1.5rem",
            borderRadius: "16px",
            display: "flex",
            flexDirection: "column",
            gap: "0.75rem",
            ".card-header": {
              background: "transparent",
              textAlign: "left",
              color: "#1C1C1C",
              "& h2": {
                fontSize: "1rem",
                fontWeight: "400",
                letterSpacing: "0.04px",
                margin: "0",
              },
            },
            ".card-body": {
              background: "transparent",
              fontSize: "1rem",
              color: "#1C1C1C",
              display: "flex",
              alignItems: "center",
              gap: "0.5rem",
              "& p": {
                fontSize: "1.625rem",
                fontWeight: "700",
                letterSpacing: "0.065px",
                margin: "0",
                paddingRight: "0.5rem",
              },
            },
          },
        }}
        className="card-container"
      >
        <Box className="card" sx={{ background: "#FEE8D0" }}>
          <div className="card-header">
            <h2>Social Sentiment</h2>
          </div>
          <div className="card-body">
            <p>{avgSentimentScore}</p> +22.00%{" "}
            <SVG src={arrowRise} alt="Rise" />
          </div>
        </Box>

        <Box className="card" sx={{ background: "#D1EBEC" }}>
          <Box className="card-header">
            <h2>CSAT Score</h2>
          </Box>
          <Box className="card-body">
            <p>{csatScore}% </p> +25.00% <SVG src={arrowRise} alt="Rise" />
          </Box>
        </Box>

        <Box className="card" sx={{ background: "#D8E6FF" }}>
          <Box className="card-header">
            <h2>NPS Score</h2>
          </Box>
          <Box className="card-body">
            <p>{nps}%</p> +21.00% <SVG src={arrowRise} alt="Rise" />
          </Box>
        </Box>

        <Box className="card" sx={{ background: "#E1E4F3" }}>
          <Box className="card-header">
            <h2>CSE Score</h2>
          </Box>
          <Box className="card-body">
            <p>0.18 to 0.28</p> -9:01% <SVG src={arrowFall} alt="Fall" />
          </Box>
        </Box>
      </Box>
    </>
  );
};
export { AnalyticsKpi };
