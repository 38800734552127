import { createSlice } from "@reduxjs/toolkit";

const userSlice = createSlice({
  name: "users",
  initialState: {
    years: null,
    userInfo: null,
  },
  reducers: {
    addYears(state, action) {
      state.years = action.payload;
    },
    fetchLoggeInUserInfo(state, action) {
      state.userInfo = action?.payload?.data[0];
    },
  },
});
export default userSlice.reducer;
export const { addYears, fetchLoggeInUserInfo } = userSlice.actions;
