import {Box, Typography } from "@mui/material";
import React from "react";
import ReactDOM from "react-dom";
const NoPage = () => {
  return (
    <>
      <Box mt={4} sx={{display: 'flex', flexDirection: 'column',alignItems: 'flex-start' , justifyContent: 'flex-start' ,  gap: .25 }}>
        <Typography variant="h5" fontWeight={400}>
            No Page 404

            Page not exist..
        </Typography>
     
      </Box>
    </>
  );
};

export {NoPage};
