import { React } from "react";
import Plot from "react-plotly.js";
import { Box } from "@mui/material";

import CircleIcon from "@mui/icons-material/Circle";
const CsatScoreChart = (props) => {
  // const legends = ["Negative", "Neutral", "Positive"];
  return (
    <>
      <Plot
        data={[
          {
            domain: props?.data[0].domain,
            gauge: props?.data[0].gauge,
            value: props?.data[0].value,
            type: "indicator",
            mode: props?.data[0].mode,
          },
        ]}
        layout={{
          width: 360,
          height: 190,
          margin: { t: 0, b: 0 },
          paper_bgcolor: "transparent",
          plot_bgcolor: "transparent",
        }}
        config={{ responsive: true, displayModeBar: false }}
      />

{/* Csat score legends */}
      {/* <Box
        position="absolute"
        bottom=".75rem"
        zIndex="9999"
        sx={{
          display: "inline-flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {legends?.map((item, i) => (
          <Box
            sx={{
              display: "inline-flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              fontSize: ".75rem",
              gap: ".5rem",
              px: ".25rem",
            }}
          >
            <CircleIcon
              fontSize=".5rem"
              style={{ color: props?.data[0]?.gauge?.steps[i]?.color }}
            />
            <Box component="span">{item}</Box>
          </Box>
        ))}
      </Box> */}
    </>
  );
};
export { CsatScoreChart };
