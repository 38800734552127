import {Box, Typography, Grid, Container, TableContainer, Table, TableHead, TableRow, TableCell, TableBody ,Paper, Button  } from "@mui/material";
import { margin } from "@mui/system";
import React from "react";
import ReactDOM from "react-dom";
import { Link } from "react-router-dom";
import DoneIcon from '@mui/icons-material/Done';
import {  createTheme } from '@mui/material/styles';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

function createData(
  name: string | jsx,
  calories: number,
  fat: number,
  carbs: number,
  protein: number,
) {
  return { name, calories, fat, carbs, protein };
}

const rows = [
  createData('Price per month', '$0.00', 'Starting at $500', 'Starting at $10 per user 3 user minimum', 'Contact us for a quote!'),
  createData('Create custom forms, reports, charts, calendars, etc', 'Unlimited', 'Unlimited', 'Unlimited', 'Unlimited'),
  createData(<Box display="flex" alignItems="center">Users and app usage <InfoOutlinedIcon /></Box>,  'Limited', 'Unlimited', 'Unlimited', 'Unlimited'),
  createData('Integrated cloud database for supporting records', 'Limited', 'Basic', 'Advanced', 'Advanced'),
  createData('3 style templates with the option to customize your own.', 'Limited', <DoneIcon/>, <DoneIcon/>, <DoneIcon/>),
  createData('Responsive across desktop, tablet and mobile devices.', 'Limited', <DoneIcon/>, <DoneIcon/>, <DoneIcon/>),
  createData('Datapages (forms, reports, charts, search queries and Pivot tables drilldowns)', '', <DoneIcon/>, <DoneIcon/>, <DoneIcon/>),
  createData('File storage capacity in account', '', '$0.02/GB/month', 'Basic', <DoneIcon/>),
  createData('Data Processing Fee', '', '$0.01/1000 data points/month', 'Basic', <DoneIcon/>),
];

const theme = createTheme({
  components: {
    // Name of the component
    MuiTableCell: {
      styleOverrides: {
        // Name of the slot
        root: {
          // Some CSS
          fontSize: '10px', 
        },
      },
    },
  },
});

const PricingPage = () => {
  return (
    <>
    <Box sx={{ background: "#F9F9FA", width: "100%" }}>
      <Container maxWidth="xl">
        <Typography variant="h2" fontWeight={700} color="#292A31" py={4}>Choose your plan</Typography>
        <TableContainer>
      <Table sx={{ minWidth: 650, }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell width="40%" align="center"></TableCell>
            <TableCell width="15%" align="center"><Box py={2} sx={{background:"rgba(216, 116, 116, 0.09);", borderRadius:"4px"}}><Typography variant="button" fontWeight={500}>Free</Typography></Box></TableCell>
            <TableCell width="15%" align="center"><Box py={2} sx={{background:"#D87474", color:"#fff", position:"relative", borderRadius:"4px"}}><Typography variant="button" fontWeight={500}>Basic <Box sx={{position: "absolute", bottom: "-19px", rotate: "90deg", left: "43%"}}><PlayArrowIcon  style={{ color: "#D87474" }}/></Box></Typography></Box></TableCell>
            <TableCell width="15%" align="center"><Box py={2} sx={{background:"rgba(216, 116, 116, 0.09);", borderRadius:"4px"}}><Typography variant="button" fontWeight={500}>Standard/plus</Typography></Box></TableCell>
            <TableCell width="15%" align="center"><Box py={2} sx={{background:"rgba(216, 116, 116, 0.09); ", borderRadius:"4px"}}><Typography variant="button" fontWeight={500}>Pro</Typography></Box></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow
              key={row.name}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row" sx={{color:"#3A3A44"}}>
              <Typography variant="button" fontWeight={400}>{row.name}</Typography>
              </TableCell>
              <TableCell align="center" sx={{color:"#3A3A44", opacity:"0.4"}}>{row.calories}</TableCell>
              <TableCell align="center"><Typography variant="button" fontWeight={500}>{row.fat}</Typography></TableCell>
              <TableCell align="center" sx={{color:"#3A3A44", opacity:"0.4"}}>{row.carbs}</TableCell>
              <TableCell align="center" sx={{color:"#3A3A44", opacity:"0.4"}}>{row.protein}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    <Box display="flex" alignItems="center" py={2} mt={2}>
      <Box flexGrow={1}><Typography variant="button" fontWeight={700}>Not sure which plan is right for you ? <Link href="#">Contact Us</Link></Typography></Box>
      <Button type="submit" variant="outlined" sx={{ '&:hover': { color: "#A45959", borderColor:"#A45959" }, marginRight:"15px", borderRadius: "4px",  color:"#D87474", borderColor:"#D87474", padding: "10px 20px", fontWeight:"500"}}>
      Buy now</Button>
                    <Button type="submit" variant="contained"  sx={{ '&:hover': { bgcolor: "#A45959" }, borderRadius: "4px", backgroundColor: "#D87474", padding: "10px 20px", fontWeight:"500"}}>
                    Continue with Individual trial
                    </Button>
    </Box>
      </Container>
      </Box>
    </>
  );
};

export {PricingPage};
