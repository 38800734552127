const { protocol, hostname, port } = window.location;

export const frontendUri = // process.env.REACT_APP_FRONTEND_URI
  hostname === "localhost"
    ? protocol + "//" + hostname + ":" + port
    : protocol + "//" + hostname;

const environment = {
  web: {
    frontendUri,
    backendUri: "http://localhost:4000", 
    flaskbackendUri: "https://v2.api-dev.aclis.io",// process.env.REACT_APP_BACKEND_URI
    //flaskbackendUri: "http://18.197.159.84:5000",

  },
  // oAuth: {
  //   github: {
  //     clientId: "02937e432749cc1b87fb", // process.env.REACT_APP_OAUTH_GITHUB_CLIENT_ID
  //     accessTokenUri: "https://github.com/login/oauth/access_token", // process.env.REACT_APP_OAUTH_GITHUB_ACCESS_TOKEN_URI
  //     authorizationUri: "https://github.com/login/oauth/authorize", // process.env.REACT_APP_OAUTH_GITHUB_AUTH_URI
  //     scopes: ["repo", "user:email", "read:org"],
  //   },
    // google: {
    //   clientId:
    //     "979818140440-27ievnqrlq3so5rf440q51jsr4c320co.apps.googleusercontent.com", // process.env.REACT_APP_SOCIAL_OAUTH_GOOGLE_CLIENT_ID
    //   authorizationUri: "https://accounts.google.com/signin/oauth",
    //   scopes: [
    //     "https://www.googleapis.com/auth/userinfo.email",
    //     "https://www.googleapis.com/auth/userinfo.profile",
    //   ],
    // },
  // },
};

switch (hostname) {
  // Development Environment
  case "dev.aclis.io":
    environment.web.backendUri = "https://api-dev.aclis.io";
    environment.web.flaskbackendUri = "https://v2.api-dev.aclis.io";
    break;

  //Prod Env

  default:
    if (process.env.REACT_APP_USE_DEV_ENV === "true") {
      environment.web.backendUri = "https://api-dev.aclis.io";
      environment.web.flaskbackendUri = "https://v2.api-dev.aclis.io";
    }
    break;
}

export default environment;
