import React,{ useEffect, useState }  from "react";
import { useNavigate  } from 'react-router-dom';
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import { AppBar, Box, IconButton, Typography, Toolbar, Tooltip } from "@mui/material";

import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import PersonAdd from '@mui/icons-material/PersonAdd';
import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';



export default function Header(props) {

  const [anchorEl, setAnchorEl] = React.useState();
  const [anchorNotification, setAnchorNotification] = React.useState();
  const open = Boolean(anchorEl);
  const openNotification = Boolean(anchorNotification);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClickNotification = (event) => {
    setAnchorNotification(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCloseNotification = () => {
    setAnchorNotification(null);
  };

   let navigate = useNavigate(); 
  const showProfilePage = () => {
    let path = "/dashboard/profilepage"; 
    navigate(path);
  }

  

  useEffect(() => {
    console.log('HEADER CALLING---->', props)
  },[props])
  
  console.log(anchorNotification);
  console.log(anchorEl);

  return (
    <AppBar
      style={{
        backdropFilter: "blur(4px) saturate(180%)",
        background: "rgba(255, 255, 255, 0.75)",
      }}
    >
      <Toolbar sx={{ flexWrap: "wrap" }}>
        <Box sx={{ flex: 1, display: "flex", alignItems: "center" }}>
          <Typography
            variant="subtitle2"
            color="#1C1C1C"
          >
            <Tooltip title={localStorage.getItem('COMPANYNAME')} placement="right">
              <Box>{localStorage.getItem('COMPANYNAME')}</Box>
              </Tooltip>
          </Typography>
        </Box>

        <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
        <Tooltip title="Notifications">
          <IconButton
            onClick={handleClickNotification}
            size="small"
            sx={{ ml: 2 }}
            aria-controls={openNotification ? 'notification' : undefined}
            aria-haspopup="true"
            aria-expanded={openNotification ? 'true' : undefined}
          > 
            <NotificationsOutlinedIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Account settings">
          <IconButton
            onClick={handleClick}
            size="small"
            sx={{ ml: 2 }}
            aria-controls={open ? 'account-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
          >
              <Avatar src={props?.data || props.userInfo?.data[0].user_avatar} sx={{ width: 32, height: 32 }}>K</Avatar>
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose} 
        props={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem onClick={showProfilePage}>
          <Avatar /> Profile
        </MenuItem>
        <Divider />
        <MenuItem onClick={handleClose}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
        

      <Menu
        anchorEl={anchorNotification}
        id="notification"
        open={openNotification}
        onClose={handleCloseNotification}
        onClick={handleCloseNotification}
        props={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem onClick={handleCloseNotification}>
        We have updated our pricing details.Have a look at what suits you the best
        </MenuItem>
        <MenuItem onClick={handleCloseNotification}>
        We have introduced new features into Aclis. Take a tour to know more
        </MenuItem>
        <Divider />
        <MenuItem onClick={handleCloseNotification}>
        We are detecting a rise in your NPS score
        </MenuItem> 
      </Menu>
        
      </Toolbar>
    </AppBar>
  );
}
