import { select, arc, interpolate } from "d3"; // Import interpolate
import { useEffect, useRef } from "react";

const OverAllCsatChart = ({ ...props }) => {
  const { score } = props;
  const containerRef = useRef(null);

  useEffect(() => {
    // Clean up previous chart
    select(containerRef.current).selectAll("svg").remove();
    drawChart(score);
  }, [score]);

  function drawChart(value) {
    const height = 262,
      width = 332;
    const tau = 2 * Math.PI;
    const maxValue = 100;
    const slice = value / maxValue;
    const innerRadius = 90,
      outerRadius = 120,
      startAngle = 0,
      cornerRadius = 40;

    const svg = select(containerRef.current)
      .append("svg")
      .attr("height", "100%")
      .attr("width", "100%")
      .attr("viewBox", `0 0 ${width} ${height}`)
      .append("g")
      .attr("transform", "translate(" + width / 2 + "," + height / 2 + ")");

    // An arc will be created
    const arcGen = arc()
      .innerRadius(innerRadius)
      .outerRadius(outerRadius)
      .startAngle(startAngle)
      .cornerRadius(cornerRadius);

    const arc1 = svg
      .append("path")
      .datum({ endAngle: tau })
      .style("fill", "#ddd")
      .attr("d", arcGen);

    const foreground = svg
      .append("path")
      .datum({ endAngle: startAngle }) // Initialize end angle to 0
      .attr("fill", "#CF4F30")
      .attr("d", arcGen);

    svg
      .append("text")
      .attr("text-anchor", "middle")
      .attr("dy", "0.35em")
      .text(`${value}%`)
      .style("font-size", "2.2em")
      .style("fill", "#040404");

    // Animate the chart on page load
    foreground
      .transition()
      .duration(2500) // Set the duration of the animation in milliseconds
      .attrTween("d", function (d) {
        const interpolateValue = interpolate(d.endAngle, slice * tau);
        return function (t) {
          d.endAngle = interpolateValue(t);
          return arcGen(d);
        };
      });
  }

  return (
    <>
      <div className="container">
        <div ref={containerRef}></div>
      </div>
    </>
  );
};

export { OverAllCsatChart };
