import { LayOutView } from "../LayOutView/LayOutView";
import { Outlet } from "react-router-dom";

const DashBoardView = () => {

  return (
    <>
    
      <LayOutView>
        {/* <div className="outlet-container">
          <Outlet />
        </div> */}


<div class="responsive-iframe">
  <iframe
    title="Report Section"
    src="https://app.powerbi.com/view?r=eyJrIjoiZWUwYTIxMTgtMTFlYy00ZDVkLTg0ZDYtOTBhNjc4NzJhYzM4IiwidCI6IjhhMTQ4OGFkLTYzNWEtNGZkMi05ODFmLTYyYWIwYTMxZmExYyJ9&pageName=ReportSectionf9d821cd500309da48d7"
    frameborder="0"
    allowFullScreen="true"
  ></iframe>
</div>
      </LayOutView>
    </>
  );
};
export { DashBoardView };
