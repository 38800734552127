// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sidebar {
    width: 103px;
    height: 100%;
    background-color: rgb(255, 255, 255);
    position: fixed;
    top: 0;
    left: 0;
    display: flex;    
    padding: 1rem ;
    box-sizing: border-box; 
    border-right: 1px solid #E1E1E3;
}
@media only screen and (max-width: 600px) {
    .sidebar {
        width: 80px;
    }
  }

ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

li {
    margin-bottom: 20px;
    text-align: center;
}

 
 `, "",{"version":3,"sources":["webpack://./src/component/SideBar/SideBar.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,YAAY;IACZ,oCAAoC;IACpC,eAAe;IACf,MAAM;IACN,OAAO;IACP,aAAa;IACb,cAAc;IACd,sBAAsB;IACtB,+BAA+B;AACnC;AACA;IACI;QACI,WAAW;IACf;EACF;;AAEF;IACI,gBAAgB;IAChB,UAAU;IACV,SAAS;AACb;;AAEA;IACI,mBAAmB;IACnB,kBAAkB;AACtB","sourcesContent":[".sidebar {\n    width: 103px;\n    height: 100%;\n    background-color: rgb(255, 255, 255);\n    position: fixed;\n    top: 0;\n    left: 0;\n    display: flex;    \n    padding: 1rem ;\n    box-sizing: border-box; \n    border-right: 1px solid #E1E1E3;\n}\n@media only screen and (max-width: 600px) {\n    .sidebar {\n        width: 80px;\n    }\n  }\n\nul {\n    list-style: none;\n    padding: 0;\n    margin: 0;\n}\n\nli {\n    margin-bottom: 20px;\n    text-align: center;\n}\n\n \n "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
