import { React } from "react";
import { Box } from "@mui/material";
import "swiper/css";
import "swiper/css/navigation";
import "./SocialSentimentView.css";
import Ellipseplaceolder from "../assets/images/ellipse-placeolder.svg";
import aclisLogo from "../assets/images/logo-aclis.svg";
// For modal
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

const RecentPainPointModel = ({ sendDataToParent, ...props }) => {
  const { open, fetchSelectedPainPoint } = props;
  const handleClose = () => {
    sendDataToParent(false);
  };
  return (
    <>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          View Detail
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "#3A3A44",
            svg: {
              color: "#3A3A44",
            },
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <Box
            sx={{
              ".card": {
                padding: "0rem",
                borderRadius: "16px",
                display: "flex",
                flexDirection: "column",
                background: "#FFF",
                gap: "0.75rem",
                ".top-section": {
                  flex: "1 0",
                  display: "flex",
                  gap: "1rem", 
                  justifyContent: "space-between",
                  ".walmart": {
                    display: "flex",
                    gap: "1rem",
                    alignItems: "flex-start",
                  },
                  ".end-text": {
                    maxWidth:"50%", 
                  },
                },
                ".card-header": {
                  background: "transparent",
                  textAlign: "left",
                  color: "#1C1C1C",
                  "& h2": {
                    fontSize: "1rem",
                    fontWeight: "400",
                    letterSpacing: "0.04px",
                    margin: "0",
                  },
                },
                ".card-body": {
                  background: "transparent",
                  fontSize: "1rem",
                  color: "#1C1C1C",
                  display: "flex",
                  alignItems: "center",
                  flexWrap: "wrap",
                  padding: "0rem",
                  gap: "1rem",
                  "& p": {
                    fontWeight: "500",
                    fontSize: "0.875rem",
                  },
                  ".card-text": {
                    flex: "1 0",
                    display: "flex",
                    flexDirection: "column",
                    gap: "1rem",

                    ".pain-text": {
                      fontSize: "1.25rem",
                      fontWeight: "600",
                      letterSpacing: "0.05px",
                      lineHeight: "1.875rem",
                      color: "#3A3A44",
                      paddingBottom: "1rem",
                      borderBottom: "1px dashed #E1E1E3",
                    },
                  },
                },
                ".card-small": {
                  display: "flex",
                  gap: "0.75rem",
                  div: {
                    flexWrap: "wrap",
                  },
                  a: {
                    color: "#A91E1D",
                    fontSize: "0.875rem",
                    "&:hover": {
                      color: "#AD5050",
                    },
                  },
                  ul: {
                    listStyleType: "disc",
                    paddingLeft: "2rem",
                    fontSize: "0.875rem",
                    li: {
                      marginBottom: "0.5rem",
                      textAlign: "left",
                      lineHeight: "150%",
                    },
                  },
                },
              },
            }}
          >
            <Box className="card">
              <Box className="top-section">
                <Box className="walmart">
                  {fetchSelectedPainPoint?.source}
                  <Box display="flex" alignItems="center" gap={1}>
                    <img src={Ellipseplaceolder} alt="Walmart" />{" "}
                    {fetchSelectedPainPoint?.severityIndex}
                  </Box>
                </Box>
                <Box className="end-text" color="#000000">{fetchSelectedPainPoint?.area}</Box>
              </Box>
              <div className="card-body">
                <Box className="card-text">
                  <Box className="pain-text">
                    {fetchSelectedPainPoint?.painPoint}
                  </Box>
                  <Box className="card-small">
                    <Box display="flex" marginBottom={3} gap={1}>
                      <Box display="flex" alignItems="center" gap={0.5}>
                        <img src={aclisLogo} alt="Walmart" width={27} />
                        <Box>
                          <strong>Aclis recommends</strong>{" "}
                        </Box>
                      </Box>

                      <Box>
                        {/* {" "}
                        <p>
                          From Kenvue's perspective, the solution to this
                          customer complaint is to:
                        </p> */}
                        <ul>
                          <li>{fetchSelectedPainPoint?.resolution}</li>
                          {/* <li>
                            It would be beneficial for Kenvue to{" "}
                            <strong>clearly label all their products</strong>{" "}
                            with <strong>detailed ingredient lists</strong>
                            so customers can easily identify which items do not
                            contain any artificial sweeteners{" "}
                          </li>
                          <li>
                            <strong>Offer more high-end options</strong> that
                            cater specifically to those looking for{" "}
                            <strong>healthier product choices</strong> without
                            sacrificing quality or flavor.{" "}
                          </li> */}
                        </ul>
                      </Box>
                    </Box>
                  </Box>
                </Box>
                {/* <img src={Listerinebig} alt="Listerine" width={254} /> */}
              </div>
            </Box>
          </Box>
        </DialogContent>
        {/* <DialogActions>
      <Button autoFocus onClick={handleClose}>
        Save changes
      </Button>
    </DialogActions> */}
      </Dialog>
    </>
  );
};
export { RecentPainPointModel };
