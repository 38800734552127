import { React, useState , useMemo, useEffect} from "react";
import PropTypes from 'prop-types';
import { Box } from "@mui/material";
import Table from '@mui/material/Table';
import IconButton from "@mui/material/IconButton";
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import Collapse from "@mui/material/Collapse";
import TableSortLabel from '@mui/material/TableSortLabel';
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { visuallyHidden } from '@mui/utils';

const columns = [
  { id: 'painPoint', label: 'Pain Point', align: 'right'},
  { id: 'source', label: 'Source', align: 'right' },
  {
    id: 'area',
    label: 'Area',
    align: 'right'
  },
  {
    id: 'severityIndex',
    label: 'Severity Index',
    align: 'right'
  },
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } =
    props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};
  return (
    <TableHead>
      <TableRow>
      <TableCell />
        {columns.map((headCell) => (
          <TableCell
            key={headCell.id}
            style={{ minWidth: headCell.minWidth }}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const Row = (props) => {
  const { row } = props;
  const [open, setOpen] = useState(false);
  return (
    <>    
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }} className={open ? "noborder" : "wd-border"} >
        <TableCell align="center">
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell scope="row" width="68%">
          <Box fontWeight={700}>{row.painPoint}</Box>
        </TableCell>
        <TableCell width="5%">{row.source}</TableCell>
        <TableCell width="5%">{row.area}</TableCell>
        <TableCell width="12%"><Box className={row.severityIndex.toLowerCase() === 'high' ? 'high-severity': row.severityIndex.toLowerCase() === 'low' ? 'low-severity': row.severityIndex.toLowerCase() === 'medium'? 'medium-severity': 'none'} >{row.severityIndex}</Box></TableCell>
      </TableRow>
      <TableRow className={open ? "wd-borderbg" : ""} >
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={5}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box pt={1} pb={3} sx={{ marginLeft:"4.062rem"}} maxWidth="calc(100% - 35%)">
              {row.resolution}
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
      
    </>
  );
                
}
const TableChart = (props) => {
  const { rowData } = props;
  const [tableRow, setTableRow] = useState(rowData);

  useEffect(() => {
    setTableRow(rowData);
  }, [rowData])
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('calories');
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);


  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = tableRow.map((n) => n.name);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  
    const visibleRows = useMemo(
    () => 
      stableSort(tableRow, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage,
      ),
    [order, orderBy, page, rowsPerPage,tableRow],
  );
    

  return (
    <>
      <Box>
     <TableContainer>
            <Table stickyHeader  sx={{ minWidth: 1140}}  aria-label="collapsible table">
              <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={tableRow.length}
            />
          <TableBody>
                {visibleRows.map((row, index) => (
            <Row key={index} row={row} />
          ))}
          </TableBody>
        </Table>
      </TableContainer> 
       <TablePagination
        rowsPerPageOptions={[5, 10, 15]}
        component="div"
        count={tableRow.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      /> 
      </Box>
    </>
  );
};
export { TableChart };
