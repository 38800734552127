import { AppBar, Toolbar, Box } from "@mui/material";
import React from "react";
import logo from "../../components/assets/images/Logo.png";
import intelligaiaLogo from "../../components/assets/images/intelligaiaLogo.svg";
import { Link, NavLink } from "react-router-dom";

const FooterDashboard = () => {
  return (
    <>
      <AppBar
        position="static"
        color="default"
        elevation={0}
        sx={{
          borderTop: (theme) => `1px solid ${theme.palette.divider}`,
          background: "#fff",
        }}
      >
        <Toolbar sx={{ flexWrap: "wrap" }}>
          <Box sx={{ flex: 1, display: "flex", alignItems: "center" }}>
            <Link variant="button" href="/landing">
              <img height="36" src={logo} alt="logo" />
            </Link>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                fontSize: "13px",
                color: "#9696A0",
                borderLeft: "1px solid #E1E1E3",
                paddingLeft: "10px",
              }}
              ml={2}
            >
              Powered by&nbsp;&nbsp;
              <img src={intelligaiaLogo} alt="Intelligaia" />
            </Box>
          </Box>
          <nav>
            <NavLink style={{ marginLeft:"15px",}}
              to="#"
              className={({ isActive }) => (isActive ? "link-active" : "link")}
            >
              About
            </NavLink>
            <NavLink style={{ marginLeft:"15px",}}
              to="#"
              className={({ isActive }) => (isActive ? "link-active" : "link")}
            >
              Support
            </NavLink>
            <NavLink style={{ marginLeft:"15px",}}
              to="#"
              className={({ isActive }) => (isActive ? "link-active" : "link")}
            >
              Contact Us
            </NavLink>
          </nav>
        </Toolbar>
      </AppBar>
    </>
  );
};

export { FooterDashboard };
