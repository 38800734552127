import { React, useState } from "react";
import { Box, Grid } from "@mui/material";
import arrowRise from "../assets/images/arrowrise.svg";
import Infoicon from "../assets/images/info-icon.svg";
import SVG from "react-inlinesvg";
import {
  SentimentTrentChart,
  OverAllCsatChart,
  WordCountChart,
  MapChart,
  SentimentScoresBySourceChart,
} from "../../pages";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

const Analytics = (props) => {
  const [open, setOpen] = useState(false);
  const [displayCsatInfo, setCsatInfo] = useState();
  const {
    WordCloud,
    map,
    lineChart,
    sentimentChart,
    cxMetrics: { avgSentimentScore, csatScore, nps, Samplesize },
  } = props.analyticsData;

  const handleClose = () => {
    setOpen(false); // for dempage code
  };

  const openCsatInfo = (csatinfo) => {
    setOpen(true);
    setCsatInfo(true);
  };

  const openNpsInfo = (npsInfo) => {
    setOpen(true);
    setCsatInfo(false);
  };
  return (
    <>
      <Box component="h3" sx={{ marginBottom: "0.5rem" }}>
        Analytics
      </Box>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          alignItems: "center",
          gap: "0.5rem",
        }}
      >
        <Box
          component="span"
          sx={{
            display: "inline-flex",
            color: "#5A5B6A",
            alignItems: "center",
            gap: "0.25rem",
          }}
        >
          Overall Average Sentiment <SVG src={Infoicon} alt="Info" />
        </Box>{" "}
        <strong>{avgSentimentScore}%</strong> 22.01%{" "}
        <SVG src={arrowRise} alt="Rise" />
      </Box>
      <Box mt={2}>
        <Grid container spacing={2}>
          <Grid item lg={4} md={6} sm={12} xs={12}>
            <Box
              sx={{
                borderRadius: "10px",
                border: "solid 1px #E9E9E9",
                background: "#FFFFFF",
                boxShadow: "none",
                height: "330px",
                "&:hover": {
                  boxShadow: 3,
                },
              }}
              p={2}
            >
              <h3>Overall Sample Size: {Samplesize}</h3>
              <Box
                mt={1}
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              ></Box>
            </Box>
          </Grid>
          <Grid item lg={4} md={6} sm={12} xs={12}>
            <Box
              sx={{
                borderRadius: "10px",
                border: "solid 1px #E9E9E9",
                background: "#FFFFFF",
                boxShadow: "none",
                "&:hover": {
                  boxShadow: 3,
                },
              }}
              p={2}
            >
              <h3>Overall CSAT SCORE</h3>
              <Box>
                CSAT Success: Achieving <strong>{csatScore}%</strong>{" "}
                <SVG
                  onClick={() => openCsatInfo(csatScore)}
                  src={Infoicon}
                  alt="Info"
                />
              </Box>

              <Box
                mt={1}
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <OverAllCsatChart score={csatScore}></OverAllCsatChart>
              </Box>
            </Box>
          </Grid>
          <Grid item lg={4} md={6} sm={12} xs={12}>
            <Box
              sx={{
                borderRadius: "10px",
                border: "solid 1px #E9E9E9",
                background: "#FFFFFF",
                boxShadow: "none",
                "&:hover": {
                  boxShadow: 3,
                },
              }}
              p={2}
            >
              <h3>Overall NPS SCORE</h3>
              <Box>
                <strong>{nps}% </strong>NPS Score Reflects Strong Performance{" "}
                <SVG
                  onClick={() => openNpsInfo(nps)}
                  src={Infoicon}
                  alt="Info"
                />
              </Box>

              <Box
                mt={1}
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <OverAllCsatChart score={nps}></OverAllCsatChart>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Box mt={2}>
        <Grid container spacing={2}>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <Box
              sx={{
                borderRadius: "10px",
                border: "solid 1px #E9E9E9",
                background: "#FFFFFF",
                boxShadow: "none",
                "&:hover": {
                  boxShadow: 3,
                },
              }}
              p={2}
            >
              <h3>Overall Sentiments Score by Sources</h3>
              <Box
                mt={1}
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <SentimentScoresBySourceChart
                  data={sentimentChart}
                ></SentimentScoresBySourceChart>
              </Box>
            </Box>
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <Box
              sx={{
                borderRadius: "10px",
                border: "solid 1px #E9E9E9",
                background: "#FFFFFF",
                boxShadow: "none",
                "&:hover": {
                  boxShadow: 3,
                },
              }}
              p={3}
            >
              <h3>Overall Sentiments Trend</h3>
              <Box
                mt={1}
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <SentimentTrentChart data={lineChart}></SentimentTrentChart>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box mt={2} pb={5}>
        <Grid container spacing={2}>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                borderRadius: "10px",
                border: "solid 1px #E9E9E9",
                background: "#FFFFFF",
                boxShadow: "none",
                "&:hover": {
                  boxShadow: 3,
                },
              }}
              p={2}
              height={1}
            >
              <h3>Word Cloud</h3>
              <Box
                mt={1}
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                <WordCountChart data={WordCloud}></WordCountChart>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box
              sx={{
                borderRadius: "10px",
                border: "solid 1px #E9E9E9",
                background: "#FFFFFF",
                boxShadow: "none",
                "&:hover": {
                  boxShadow: 3,
                },
              }}
              p={3}
              //  height="17.75rem"
            >
              <h3>Sentiments Scores by Region</h3>
              <Box
                mt={1}
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  filter: map && map.length > 1 ? "none" : "blur(5px)", // Apply blur if no data
                }}
                fontSize="1rem"
                fontWeight="500"
                height={1}
              >
                <MapChart data={map}></MapChart>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth
        maxWidth="md"
      >
        {displayCsatInfo ? (
          <>
            <DialogTitle>
              <h2>Your CSAT Score is {csatScore}%</h2>
            </DialogTitle>
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: "#3A3A44",
                svg: {
                  color: "#3A3A44",
                },
              }}
            >
              <CloseIcon />
            </IconButton>
            <DialogContent dividers>
              <Box>
                <h4>CSAT{">="}70</h4>
                <p>
                  "Exhilarating Experience: CSAT Score of 92 ignites Customer
                  Elation"
                </p>
                <hr />
              </Box>
              <Box>
                <h4>Neutral CSAT: CSAT between 50-70</h4>
                <p>
                  "Room for Enhancement: CSAT Score of 65 Unveils Untapped
                  Potential"
                </p>
              </Box>
              <hr />
              <Box>
                <h4>Bad CSAT: CSAT {"<"}50</h4>
                <p>
                  "Urgent Call to Action: CSAT Score of 28 Demands Immediate
                  Attention"
                </p>
              </Box>
            </DialogContent>
          </>
        ) : (
          <>
            <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
              <h2>Your NPS Score is {nps}%</h2>
            </DialogTitle>
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: "#3A3A44",
                svg: {
                  color: "#3A3A44",
                },
              }}
            >
              <CloseIcon />
            </IconButton>
            <DialogContent dividers>
              <Box>
                <h4>Good NPS; NPS{">="}75</h4>
                <p>
                  "Exceeding Expectations: Stellar NPS Score of 78 Showcases
                  Customer Satisfaction"
                </p>
                <hr />
              </Box>
              <Box>
                <h4>
                  Neutral NPS: 50{">="}NPS {"<="}75
                </h4>
                <p>
                  "Room for Growth: NPS Score of 40 Signals Opportunity for
                  Enhancements"
                </p>
              </Box>
              <hr />
              <Box>
                <h4>NPS {"<"} 25</h4>
                <p>"NPS Score of 25 Highlights Areas for improvement"</p>
              </Box>
            </DialogContent>
          </>
        )}
      </Dialog>
    </>
  );
};
export { Analytics };
