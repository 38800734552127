import { LayOutView } from "../LayOutView/LayOutView";
const CompetitiveAnalysis = () => {
  return (
    <>
      <LayOutView>
        <h1>CompetitveAnalysis page</h1>
      </LayOutView>
    </>
  );
};
export { CompetitiveAnalysis };
