import {Box, Typography } from "@mui/material";
import React from "react";
import ReactDOM from "react-dom";
import MaximiseCustomerInsights from "./MaximiseCustomerInsights";
import MaximisingProductPotential from "./MaximisingProductPotential";
import RevolutioniseCustomerInsights from "./RevolutioniseCustomerInsights";
import Clients from './Clients';
const LandingPage = () => {
  return (
    <>
      <Box  mt={4} sx={{display: 'flex', flexDirection: 'column',alignItems: 'flex-start' , justifyContent: 'flex-start' ,  gap: .25 }}>
        {/* <Typography variant="h5" fontWeight={400}>
            Landing Page Container  
        </Typography> */}
       <MaximiseCustomerInsights></MaximiseCustomerInsights>
       <RevolutioniseCustomerInsights></RevolutioniseCustomerInsights>
       {/* <Clients></Clients> */}
       <MaximisingProductPotential></MaximisingProductPotential>
      </Box>
    </>
  );
};

export {LandingPage};
