import React from "react"; 
import {Box} from "@mui/material";

const ContactUsPage = () => {
    return(
        <>
        <Box>Contact Us</Box>
        </>
    );
};
export {ContactUsPage};