import React from "react";
import { Box, styled } from "@mui/material";
const ChartCount = styled("h6")(({ theme }) => ({
  padding: theme.spacing(1),
  [theme.breakpoints.down("md")]: {
    fontSize: "1.5rem",
  },
  [theme.breakpoints.up("lg")]: {
    fontSize: "1.5rem",
  },
  [theme.breakpoints.down("xl")]: {
    fontSize: "1.5rem",
  },
  [theme.breakpoints.up("sm")]: {
    fontWeight: "600",
    margin: "0",
    padding: "0",
  },
}));
const AverageSentimentChart = (props) => {
  return (
    <>
      <Box>
        <ChartCount>{props.data?.avgSentimentScore}%</ChartCount>
      </Box>
    </>
  );
};
export { AverageSentimentChart };
