import {Box, Typography, Container, Button} from "@mui/material";
import React from "react";

import secureApplication from '../../components/assets/images/secureApplication.png';



const RevolutioniseCustomerInsights = () => {
  return (
    <>
   <Box sx={{width:"100%"}} pt={10} pb={10}>
    <Container maxWidth="xl">
   <Box pb={3}>
      <Typography variant="h2" fontWeight={500} textAlign="left">
      Aclis providing Insights and Driving Growth: The Power of Customer Analytics and Behaviour Tracking in Diverse Business Use Cases
          </Typography>
          <Typography variant="body1" fontWeight={400} textAlign="left" mt={2}>
          Built for different use cases for different teams
          </Typography>
          </Box>
     <Box sx={{ display: "flex", flexDirection: "row", justifyContent:'space-between', alignItems:"flex-start",  }}>
        <Box sx={{ width: "50%", display: "flex", flexDirection: "column", justifyContent: "flex-start" }}>
        <Box alignContent="left">
          <img src={secureApplication} alt={secureApplication} width="100%" />
        </Box>
        </Box>
     
      <Box sx={{ width: "45%", display: "flex",flexDirection: "column", justifyContent: "flex-start" }} pt={1}>
      <Box sx={{ borderBottom:"1px solid #E1E1E3"}} pb={2} mb={1}>
      <Typography variant="h5" fontWeight={400} textAlign="left" py={1} color="#292A31">
      High Tech Enterprise
          </Typography>
        <Typography variant="body1" fontWeight={300} textAlign="left" color="#19191D">
        High-tech enterprises use aclis to gain a comprehensive understanding of the target market, inform and improve overall business strategy, make data-driven decisions, and design and implement customer service strategies.
          </Typography>
        </Box>
        <Box sx={{ borderBottom:"1px solid #E1E1E3"}} pb={2} mb={1}>
      <Typography variant="h5" fontWeight={400} textAlign="left" py={1} color="#292A31">
      Logistics
          </Typography>
        <Typography variant="body1" fontWeight={300} textAlign="left" color="#19191D">
        Can use aclis to optimise marketing spend, understand customer behaviour, improve customer experience, and keep up with changes in the market and customer preferences.
          </Typography>
        </Box>
        <Box sx={{ borderBottom:"1px solid #E1E1E3"}} pb={2} mb={1}>
      <Typography variant="h5" fontWeight={400} textAlign="left" py={1} color="#292A31">
      Retail
          </Typography>
        <Typography variant="body1" fontWeight={300} textAlign="left" color="#19191D">
        Aclis can help retail businesses to understand customer preferences and behaviour, optimise marketing spend, improve customer experience, and identify customer segments for targeted sales efforts.
          </Typography>
        </Box>
        <Box pb={4} mb={2}>
      <Typography variant="h5" fontWeight={400} textAlign="left" py={1} color="#292A31">
      Healthcare
          </Typography>
        <Typography variant="body1" fontWeight={300} textAlign="left" color="#19191D">
        Improve doctor/ patient experience, make data-driven decisions, and design and implement customer service strategies.
          </Typography>
        </Box>
      
      </Box>
      </Box>
      </Container>
      </Box>
    </>
  );
};

export default RevolutioniseCustomerInsights;
