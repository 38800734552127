import { Box, Typography } from "@mui/material";
import React from "react";
import ReactDOM from "react-dom";
const CsatScore = () => {
  return (
    <>
    <Box width={1} mt={10} sx={{ display: "flex", flexDirection: "column", alignItems: "flex-end",}}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-end",
            justifyContent: "flex-end",
            gap: 0.25,
            width:"29rem"
          }}
        >
          <Box
            pb={2.5}
            width={1}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              gap: 0.25,
             }}
          >
            <Typography variant="body1" color="#FFF" fontWeight={400}>
            &lt;Comapny Name&gt;
            </Typography>
            <Typography
              variant="h2"
              fontWeight={400}
              color="#FFF"
              sx={{ textTransform: "uppercase", paddingBottom:"1.5rem"}}
            >
              Csat Score
            </Typography>
            <img src="https://via.placeholder.com/325x150/FFF/000?text=CSATScore" alt="Bank Logo" />
          </Box>
          <Box
            py={2.5}
            width={1}
            sx={{
              display: "flex",  
              flexDirection: "column",
              alignItems: "start",
              gap: 0.25,
              borderTop: 1,
              borderColor: "#fff",
            }}
            marginBottom="10rem"
          >
            <Typography variant="h6" color="#FFF" fontWeight={300} sx={{ paddingBottom:"1.5rem"}}>
                Average CSAT score for Banking* (derived in 2020) is 78
            </Typography>
            <Typography variant="h6" color="#FFF" fontWeight={300} sx={{ paddingBottom:"1.5rem"}}>
                CSAT Score and Renewal Rate are the key parameters that help UX Design team track user satisfaction.
            </Typography>
            <Typography variant="body2" color="#FFF" fontWeight={300}>* American Customer Satisfaction Index</Typography>
          
          </Box>
        </Box>
    </Box>
    </>
  );
};

export default CsatScore;
