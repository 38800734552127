import { Box, Typography, Button, Container} from "@mui/material";
import React from "react";
import { useNavigate  } from "react-router-dom";
import customerInsight from '../../components/assets/images/customerInsight.png';
import hp from '../../components/assets/images/hp.png';
import cisco from '../../components/assets/images/cisco.png';
import docuSign from '../../components/assets/images/docuSign.png';


const MaximiseCustomerInsights = () => {
  const navigate = useNavigate();
  const demoPage = () => {
    navigate("/demopage");
  }
  return (
    <>
      <Container maxWidth="xl" >
        <Box sx={{ display: "flex", flexDirection: "row", justifyContent: 'space-between', alignItems: "flex-start", gap:"3rem", marginY:"3rem"}}>
          <Box sx={{ width: "40%", display: "flex", flexDirection: "column", justifyContent: "flex-start" }} pb={4}>
            <Typography variant="h1" fontWeight={500} textAlign="left">
              Maximise customer insights through active listening with aclis
            </Typography>
            <Typography variant="h6" mt={3} fontWeight={400}>
              Gain a 360-degree view of your customer's needs with aclis
            </Typography>
            <Box mt={10}><Button onClick={demoPage}  variant="contained" sx={{ '&:hover': { bgcolor: "#A45959" }, borderRadius: "4px", backgroundColor: "#D87474", padding: "10px 20px" }}>
              Get a Free Demo
            </Button></Box>
          </Box>

          <Box px={2} pb={5} sx={{ width: "50%", display: "flex", flexDirection: "column", justifyContent: "flex-start" }}>
            <Box alignContent="center" borderRadius={3} boxShadow={20}>
              <img src={customerInsight} alt={customerInsight} width="100%" />
            </Box>
          </Box>
        </Box>
      </Container>

      <Box sx={{ width: "100%", background: "radial-gradient(48.4% 1372.05% at 3.56% -19.75%, #FEEDEC 0%, rgba(254, 237, 236, 0.546265) 45.37%, rgba(254, 237, 236, 0) 100%)" }} py={5}>


        <Container maxWidth="xl">
        <Box sx={{ display: "flex", flexDirection: "row", justifyContent: 'space-between', alignItems: "flex-start", gap:"3rem", marginY:"1.5rem"}}>
          <Box sx={{ width: "32%", display: "flex", flexDirection: "column", justifyContent: "flex-start" }} pb={1}>
          <Typography variant="h2" fontWeight={700} color="#4A4B57">
                Trusted Clients
              </Typography>
              <Box mt={2}><Typography variant="body1" fontWeight={400} color="#3A3A44">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt
              </Typography>
            </Box>
            </Box>
            <Box sx={{ width: "55%", display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems:"center" }} pb={1}>
            <Box>
                <img src={hp} alt={hp} width="100%" />
              </Box>
              <Box>
                <img src={cisco} alt={cisco} width="100%" />
              </Box>
              <Box>
                <img src={docuSign} alt={docuSign} width="100%" />
              </Box>
            </Box>
            </Box>
        </Container>
      </Box>
    </>
  );
};

export default MaximiseCustomerInsights;
