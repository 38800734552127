import React, { useEffect, useState } from "react";
import {
  Box,
  Container,
  Grid,
  Link,
  Typography,
  styled,
  IconButton,
  Tooltip,
  tooltipClasses,
  Snackbar,
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import {
  SampleSizeChart,
  SentimentScoresBySourceChart,
  AverageSentimentChart,
  NpsScoreChart,
  CsatScoreChart,
  WordCountChart,
  SentimentTrentChart,
  MapChart,
  TableChart,
} from "../../pages";
import { useFetchDashboardChartsData } from "../../hooks/chartHook";
import { Loader } from "../../components";
import StickyBox from "react-sticky-box";
import { FooterDashboard } from "../Dashboard/FooterDashboard";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const ChartHeader = styled("h6")(({ theme }) => ({
  padding: theme.spacing(1),
  [theme.breakpoints.down("md")]: {
    fontSize: "1rem",
  },
  [theme.breakpoints.up("lg")]: {
    fontSize: "1rem",
  },
  [theme.breakpoints.down("xl")]: {
    fontSize: ".85rem",
  },
  [theme.breakpoints.up("sm")]: {
    fontWeight: "500",
    margin: "0",
    padding: "0",
  },
}));

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
    fontSize: theme.typography.pxToRem(12),
  },

  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 180,
    },
  },
};

const SocialSentiment = () => {
  const [year, setYear] = useState([]);
  const { fetchChartData } = useFetchDashboardChartsData();
  const {
    mutate: mutateFetchRecord,
    isLoading: isLoadingDashboard,
    isError: isDashboardError,
    data: userInfo,
  } = fetchChartData;

  useEffect(() => {
    mutateFetchRecord({
      year: year,
      month: [],
    });
  }, [year]);

  const handleChange = (event) => {
    if (event.target.value.toLowerCase() === "all") {
      setYear([]);
    } else {
      setYear([Number(event.target.value)]);
    }
  };

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={isDashboardError}
        autoHideDuration={6000}
      >
        <Alert severity="error" sx={{ width: "100%" }}>
          {
            '"Oops! An error occurred. Please check your internet connection and try again. If the problem persists, please refresh the page.'
          }
        </Alert>
      </Snackbar>
      {isLoadingDashboard || userInfo === undefined ? (
        <Container maxWidth="xl">
          <Loader></Loader>
        </Container>
      ) : (
        <>
          <StickyBox
            offsetTop={64}
            offsetLeft={100}
            offsetBottom={20}
            shadow
            style={{
              backdropFilter: "blur(100px) saturate(180%)",
              zIndex: "5",
              boxShadow: "4",
            }}
          >
            <Container maxWidth="xl" sx={{ position: "relative" }}>
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Box position="relative">
                  <Box
                    sx={{
                      display: "inline-flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                      gap: ".5rem",
                      mt: ".75rem",
                    }}
                  >
                    <Link underline="hover" color="inherit" href="/dashboard">
                      <Typography variant="body2" color="#1976d2">
                        Dashboard
                      </Typography>
                    </Link>
                    <Box component="span" fontSize="1rem">
                      /
                    </Box>
                    <Typography variant="body2" color="#000000de">
                      Social Sentiment
                    </Typography>
                  </Box>
                  <Box
                    mb={1}
                    display="flex"
                    alignItems="center"
                    flexDirection="row"
                  >
                    <Typography variant="h6">Social Sentiment</Typography>
                  </Box>
                </Box>
                <Box display="inline-flex" width="10rem">
                  <FormControl fullWidth size="small" variant="standard">
                    <InputLabel id="year-select-label">Select Years</InputLabel>

                    <Select
                      labelId="year-select-label"
                      id="year-select"
                      value={year.length > 0 ? year[0] : "All"}
                      label="Select Years"
                      onChange={handleChange}
                      MenuProps={MenuProps}
                    >
                      {Object.keys(userInfo?.yearListDropdown).map(
                        (item, pos) => {
                          return (
                            <MenuItem
                              key={userInfo?.yearListDropdown[item]}
                              value={userInfo?.yearListDropdown[item]}
                            >
                              {userInfo?.yearListDropdown[item]}
                            </MenuItem>
                          );
                        }
                      )}
                    </Select>
                  </FormControl>
                </Box>
              </Box>
            </Container>
          </StickyBox>
          <Container maxWidth="xl" sx={{ position: "relative" }}>
            <Box>
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <Box
                    sx={{
                      borderRadius: "10px",
                      border: "solid 1px #E9E9E9",
                      background: "#ffffff",
                      "&:hover": {
                        boxShadow: 3,
                      },
                    }}
                    p={2}
                  >
                    <Box
                      alignItems="center"
                      display="flex"
                      justifyContent="space-between"
                      flexDirection="row"
                      sx={{ width: "100%" }}
                    >
                      <ChartHeader>Sample Size</ChartHeader>
                      <BootstrapTooltip
                        describeChild
                        title="The sample size is an important factor to consider while analyzing data, as a small sample size may lead to inaccurate conclusions."
                        placement="top"
                      >
                        <IconButton>
                          <InfoOutlinedIcon />
                        </IconButton>
                      </BootstrapTooltip>
                    </Box>
                    <Box
                      mt={3}
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <SampleSizeChart
                        data={userInfo?.cxMetrics}
                      ></SampleSizeChart>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      borderRadius: "10px",
                      border: "solid 1px #E9E9E9",
                      background: "#FFFFFF",

                      boxShadow: "none",
                      "&:hover": {
                        boxShadow: 3,
                      },
                    }}
                    p={2}
                    mt="1.0125rem"
                  >
                    <Box
                      alignItems="center"
                      display="flex"
                      justifyContent="space-between"
                      flexDirection="row"
                      sx={{ width: "100%" }}
                    >
                      <ChartHeader>Average Sentiment</ChartHeader>
                      <IconButton>
                        <InfoOutlinedIcon />
                      </IconButton>
                    </Box>
                    <Box
                      mt={3}
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <AverageSentimentChart
                        data={userInfo?.cxMetrics}
                      ></AverageSentimentChart>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={4}>
                  <Box
                    sx={{
                      borderRadius: "10px",
                      border: "solid 1px #E9E9E9",
                      background: "#FFFFFF",

                      boxShadow: "none",
                      "&:hover": {
                        boxShadow: 3,
                      },
                    }}
                    p={2}
                  >
                    <Box
                      alignItems="center"
                      display="flex"
                      justifyContent="space-between"
                      flexDirection="row"
                      sx={{ width: "100%" }}
                    >
                      <ChartHeader>CSAT Score</ChartHeader>
                      <BootstrapTooltip
                        describeChild
                        title="It is important to gather data from multiple sources to ensure a comprehensive understanding of customer feedback."
                        placement="top"
                      >
                        <IconButton>
                          <InfoOutlinedIcon />
                        </IconButton>
                      </BootstrapTooltip>
                    </Box>
                    <Box
                      mt={1}
                      position="relative"
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                      height={1}
                    >
                      <CsatScoreChart
                        data={userInfo?.csatGauge}
                      ></CsatScoreChart>
                    </Box>
                  </Box>
                </Grid>

                <Grid item xs={4}>
                  <Box
                    sx={{
                      borderRadius: "10px",
                      border: "solid 1px #E9E9E9",
                      background: "#FFFFFF",
                      boxShadow: "none",
                      "&:hover": {
                        boxShadow: 3,
                      },
                    }}
                    p={2}
                  >
                    <Box
                      alignItems="center"
                      display="flex"
                      justifyContent="space-between"
                      flexDirection="row"
                      sx={{ width: "100%" }}
                    >
                      <ChartHeader>NPS Score</ChartHeader>
                      <BootstrapTooltip
                        describeChild
                        title="NPS (Net Promoter Score) is primarily calculated based on customer feedback.
- Social media data may not be the best source to calculate NPS as it may not be representative of the overall customer base."
                        placement="top"
                      >
                        <IconButton>
                          <InfoOutlinedIcon />
                        </IconButton>
                      </BootstrapTooltip>
                    </Box>
                    <Box
                      position="relative"
                      mt={1}
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <NpsScoreChart data={userInfo?.npsGauge}></NpsScoreChart>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <Box mt={2}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Box
                    sx={{
                      borderRadius: "10px",
                      border: "solid 1px #E9E9E9",
                      background: "#FFFFFF",
                      boxShadow: "none",
                      "&:hover": {
                        boxShadow: 3,
                      },
                    }}
                    p={2}
                  >
                    <ChartHeader>Sentiments Score by Sources</ChartHeader>
                    <Box
                      mt={1}
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <SentimentScoresBySourceChart
                        data={userInfo?.sentimentChart}
                      ></SentimentScoresBySourceChart>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box
                    sx={{
                      borderRadius: "10px",
                      border: "solid 1px #E9E9E9",
                      background: "#FFFFFF",
                      boxShadow: "none",
                      "&:hover": {
                        boxShadow: 3,
                      },
                    }}
                    p={3}
                  >
                    <ChartHeader>Sentiments Trend</ChartHeader>
                    <Box
                      mt={1}
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <SentimentTrentChart
                        data={userInfo?.lineChart}
                      ></SentimentTrentChart>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Box>

            <Box mt={2}>
              <Grid>
                <Grid item>
                  <Box
                    sx={{
                      borderRadius: "10px",
                      border: "solid 1px #E9E9E9",
                      background: "#FFFFFF",
                      boxShadow: "none",
                      "&:hover": {
                        boxShadow: 3,
                      },
                    }}
                    p={2}
                  >
                    <ChartHeader>Pain points</ChartHeader>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%",
                      }}
                    >
                      <TableChart
                        rowData={userInfo?.painPointTable}
                      ></TableChart>
                      {/* <SentimentScoresBySourceChart
                        data={userInfo?.sentimentChart}
                      ></SentimentScoresBySourceChart> */}
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <Box mt={2} pb={5}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      borderRadius: "10px",
                      border: "solid 1px #E9E9E9",
                      background: "#FFFFFF",
                      boxShadow: "none",
                      "&:hover": {
                        boxShadow: 3,
                      },
                    }}
                    p={2}
                    height={1}
                  >
                    <ChartHeader>Word Cloud</ChartHeader>
                    <Box
                      mt={1}
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "flex-start",
                        alignItems: "center",
                      }}
                    >
                      <WordCountChart
                        data={userInfo?.WordCloud}
                      ></WordCountChart>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box
                    sx={{
                      borderRadius: "10px",
                      border: "solid 1px #E9E9E9",
                      background: "#FFFFFF",
                      boxShadow: "none",
                      "&:hover": {
                        boxShadow: 3,
                      },
                    }}
                    p={3}
                    //  height="17.75rem"
                  >
                    <ChartHeader>User Footprint</ChartHeader>
                    <Box
                      mt={1}
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                      fontSize="1rem"
                      fontWeight="500"
                      height={1}
                    >
                      <MapChart data={userInfo?.map}></MapChart>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Box>

            <FooterDashboard />
          </Container>
        </>
      )}
    </>
  );
};
export { SocialSentiment };
