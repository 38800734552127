import { React, useState } from "react";
const CohortAnalysis = () => {
  console.log("YES ITS CALLING");
  return (
    <>
      <div class="responsive-iframe">
        <iframe
          title="Report Section"
          width="600"
          height="373.5"
          src="https://app.powerbi.com/view?r=eyJrIjoiZGU3ZjQ1NjctMjRiNy00N2UwLWIxYTUtMzNlNzFlNjYxZDMxIiwidCI6IjhhMTQ4OGFkLTYzNWEtNGZkMi05ODFmLTYyYWIwYTMxZmExYyJ9"
          frameborder="0"
          allowFullScreen="true"
        ></iframe>
      </div>
    </>
  );
};
export { CohortAnalysis };
