import { AppBar, Toolbar, Box, Button, Link } from "@mui/material";
import React from "react";
import logo from '../assets/images/Logo.png';
import { NavLink } from "react-router-dom";
import "./Header.scss";

const Header = () => {
  return (
    <>
      <AppBar
        position="static"
        color="default"
        className="mainNav"
        sx={{ borderBottom: (theme) => `1px solid ${theme.palette.divider}`, background: "#fff" }}
      >
        <Toolbar sx={{ flexWrap: 'wrap' }} >
          <Box sx={{ flex: 1, display: "flex", alignItems: "center" }}>
            <Link variant="button"
              href="/landing">
              <img src={logo} alt='logo' />
            </Link>
            <nav>
              <Link
                variant="button"
                color="text.primary"
                underline="none"
                href="#"
               sx={{ my: 2, mx: 1.5, cursor: 'not-allowed', opacity: 0.6 }}
               disabled // Add the disabled attribute
              >
                Features
              </Link>
              <Link
                variant="button"
                color="text.primary"
                underline="none"
                href="#"
                sx={{ my: 2, mx: 1.5, cursor: 'not-allowed', opacity: 0.6 }}
                disabled // Add the disabled attribute
              >
                Resources
              </Link>
              <Link
                variant="button"
                color="text.primary"
                underline="none"
                href="pricing"
                sx={{ my: 2, mx: 1.5 }}
              >
                Pricing
              </Link>
            </nav>
          </Box>
          <nav>
            <NavLink
              to=""
              className={({ isActive }) => (isActive ? "link-active" : "link")}
            >
              Contact Us
            </NavLink>
            <NavLink
              to="/login"
              className={({ isActive }) => (isActive ? "link-active" : "link")} style={{color:"#D87474", fontWeight:"700"}}
            >
              Log in
            </NavLink>
          </nav>
        </Toolbar>
      </AppBar>
    </>
  );
};

export {Header};
