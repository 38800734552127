import { Box } from "@mui/material";
import React from "react";
import Metrix from "./Mertix/Metrix";
import AclisReports from "./AclisReport";
import FormativeUsabilityScore from "./Mertix/FormativeUsabilityScore";
import Fcr from "./Mertix/Fcr";
import NetPromoterScore from "./Mertix/NetPromoterScore";
import CsatScore from "./Mertix/CsatScore";

const DemoPage = () => {
  return (
    <>
      <Box
        mt={4}
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          justifyContent: "flex-start",
          gap: 0.25,
        }}
      >
        <Box
          display="flex"
          flexDirection="column"
          maxWidth="lg"
          marginX="auto"
          style={{ gap: "2rem" }}
        >
          <AclisReports></AclisReports>
        </Box>
        <Box backgroundColor="#DB664E" width={1}>
          <Box
            maxWidth="lg"
            display="flex"
            flexDirection="column"
            width={1}
            marginX="auto"
            style={{ gap: "2rem" }}
          >
            <Metrix></Metrix>
            <CsatScore></CsatScore>
            <NetPromoterScore></NetPromoterScore>
            <Fcr></Fcr>
            <FormativeUsabilityScore></FormativeUsabilityScore>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export { DemoPage };
