import { LayOutView } from "../LayOutView/LayOutView";
const HomeView = () => {
  return (
    <>
      <LayOutView>
        <h1>HOME PAGE WITH TILES</h1>;
      </LayOutView>
    </>
  );
};
export { HomeView };
