import React from "react";
import {BrowserRouter, Route, Link } from "react-router-dom";

function Navbar() {
 return(
    <nav>
        <ul>
            <li>
                <Link to="/">Home</Link>
            </li>
            <li>
                <Link to="/demo">Demo</Link>
            </li>
            <li>
                <Link to="/landing">Landing</Link>
            </li>  
        </ul>
    </nav>
 );
}

export default Navbar;