import { React } from "react";
import Plot from "react-plotly.js";

const SentimentTrentChart = (props) => {
  return (
    <>
      <Plot
        data={props?.data}
        layout={{
          dragmode: false,
          width: 550,
          height: 210,
          barmode: "scatter",
          legend: { orientation: "h" },
          margin: { t: 0, b: 0, l: 0, r: 0 },
          paper_bgcolor: "transparent",
          plot_bgcolor: "transparent",
        }}
        config={{ responsive: true, displayModeBar: false }}
      />
    </>
  );
};
export { SentimentTrentChart };
