import { Box, Typography } from "@mui/material";
import React from "react";
import ReactDOM from "react-dom";
const Fcr = () => {
  return (
    <>
    <Box width={1} mt={5} sx={{ display: "flex", flexDirection: "column", alignItems: "flex-end",}}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-end",
            justifyContent: "flex-end",
            gap: 0.25,
            width:"29rem"
          }}
        >
          <Box
            pb={2.5}
            width={1}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              gap: 0.25,
             }}
          >
            <Typography variant="body1" color="#FFF" fontWeight={400}>
              Industry Benchmark Average for 
            </Typography>
            <Typography
              variant="h2"
              fontWeight={400}
              color="#FFF"
              sx={{ textTransform: "uppercase", paddingBottom:"1.5rem"}}
            >
              Fcr
            </Typography>
            <Box sx={{  display: "flex",
            flexDirection: "row",
            alignItems: "flex-start",
            justifyContent: "flex-end",}}>
              <Box fontWeight={500} fontSize={160} color="#fff">70</Box>
              <Box fontWeight={500} fontSize={50} color="#fff" pt={4}>%</Box>
            </Box>
          </Box>
          <Box
            py={2.5}
            width={1}
            sx={{
              display: "flex",  
              flexDirection: "column",
              alignItems: "start",
              gap: 0.25,
              borderTop: 1,
              borderColor: "#fff",
            }}
            marginBottom="10rem"
          >
            <Typography variant="h6" color="#FFF" fontWeight={300} sx={{ paddingBottom:"1.5rem"}}>
              As a CX Manager, you can count <Box as="span" fontWeight={500}>First Call Resolution (FCR) Escalations and resolution time</Box> for product and service quality
            </Typography>
            <Typography variant="body2" color="#FFF" fontWeight={300}>Call Center FCR Experts (SQM Group)</Typography>
          
          </Box>
        </Box>
    </Box>
    </>
  );
};

export default Fcr;
