import { React } from "react";
import Plot from "react-plotly.js";
import Box from "@mui/material/Box";

const MapChart = (props) => {
  return (
    <>
      <Box>
        <Plot
          data={props?.data}
          layout={{
            dragmode: false,
            width: 500,
            height: 315,
            margin: {
              l: 0,
              t: 0,
              b: 0,
              pad: {
                l: 0,
                r: 0,
                b: 0,
                t: 0,
              },
            },
            geo:{
              bgcolor: 'rgba(0,0,0,0)',
              showframe: false,
              projection:{
                type: 'mercator'
              }
            },
            paper_bgcolor: "transparent",
            plot_bgcolor: "transparent",
          }}
          config={{ responsive: true, displayModeBar: false }}
        />
      </Box>
    </>
  );
};
export { MapChart };
