import * as React from "react";
import { Typography, Grid, Box } from "@mui/material";
const AclisReports = () => {
  return (
    <>
      <Box sx={{ display: "flex", flexDirection: "row", justifyContent:'space-between', alignItems:"flex-start",  }}>
        <Box sx={{ width: "40%", display: "flex", justifyContent: "center" }}>
          <Typography variant="h2" fontWeight={300} textAlign="left">
          Our proprietary data model indicates a staggering 
            <Box as="span" fontWeight={500}>
            &lt;Percentage&gt; of mobile users have voiced a negative sentiment on the &lt;Company Name&gt;
            </Box> 
            app across a plethora of today’s most trafficked platforms.
          </Typography>
        </Box>
     
      <Box px={2} sx={{ width: "45%", display: "flex",flexDirection: "column", justifyContent: "center" }}>
        <Typography variant="h6" fontWeight={300} textAlign="left">
        &lt;Placeholder Text&gt;
        </Typography>
        <Box py={2} alignContent="center" sx={{ borderBottom: 5}}>
          <img src="https://via.placeholder.com/540x250?text=GoogleMapPlaceholder" alt="Google Map Placeholder" width="100%" />
        </Box>
      </Box>
      </Box>

      <Box
        alignContent="center"
        mx="auto"
        sx={{ width: "75%", display: "flex", justifyContent: "center", marginBottom:"8.437rem" }}
      >
        <img src="https://via.placeholder.com/900?text=DonutChartPlaceholder" alt="Chart Placeholder" width="100%" />
      </Box>
    </>
  );
};

export default AclisReports;
