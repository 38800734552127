import { Box, Typography } from "@mui/material";
import React from "react";
import ReactDOM from "react-dom";

const NetPromoterScore = () => {
  return (
    <>
      <Box
        width={1}
        mt={5}
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-end",
            justifyContent: "flex-end",
            gap: 0.25,
            width: "35rem",
          }}
        >
          <Box
            pb={2.5}
            width={1}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              gap: 0.25,
            }}
          >
            <Typography variant="body1" color="#FFF" fontWeight={400}>
            &lt;Comapny Name&gt;
            </Typography>
            <Typography
              variant="h2"
              fontWeight={400}
              color="#FFF"
              sx={{ paddingBottom: "6rem" }}
            >
              Net Promoter Score -2%
            </Typography>
            <img src="https://via.placeholder.com/550x200/FFF/000?text=NetPromotorScore" alt="NPS Chart Placeholder" />
          </Box>
          <Box
            py={2.5}
            width={1}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "start",
              gap: 0.25,
              borderTop: 1,
              borderColor: "#fff",
            }}
            marginBottom="5rem"
          >
            <Typography
              variant="h6"
              color="#FFF"
              fontWeight={300}
              sx={{ paddingBottom: "1.5rem" }}
            >
              NPS Score and Churn rate can help product teams identify loyal
              users.
            </Typography>
            <Typography
              variant="h6"
              color="#FFF"
              fontWeight={300}
              sx={{ paddingBottom: "1.5rem" }}
            >
              Track Advocacy through online reviews
            </Typography>
            <Typography variant="body2" color="#FFF" fontWeight={300}>
              *monkeylearn.com
            </Typography>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default NetPromoterScore;
