import { React } from "react";
import { Box } from "@mui/material";

const WordCountChart = (props) => {
  return (
    <>
      <Box width={1} height={1} p={1} mt={2}>
        <img
          src={props?.data}
          alt="BigCo Inc. logo"
          style={{ maxWidth: "100%" }}
        />
      </Box>
    </>
  );
};
export { WordCountChart };
