import { React } from "react";
import Plot from "react-plotly.js";
import Box from "@mui/material/Box";

const SentimentScoresBySourceChart = (props) => {
  return (
    <>
      <Box>
        <Plot
          data={props?.data}
          layout={{
            dragmode: false,
            width: 500,
            height: 220,
            barmode: "stack",
            legend: { orientation: "h" },
            autosize: true,
            paper_bgcolor: "transparent",
            plot_bgcolor: "transparent",

            margin: {
              l: 50,
              r: 0,
              b: 0,
              t: 0,
              pad: {
                l: 0,
                r: 0,
                b: 0,
                t: 1,
              },
            },
          }}
          config={{ responsive: true, displayModeBar: false }}
        />
      </Box>
    </>
  );
};
export { SentimentScoresBySourceChart };
