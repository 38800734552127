import { Box, Typography } from "@mui/material";
import React from "react";
import ReactDOM from "react-dom";
const FormativeUsabilityScore = () => {
  return (
    <>
      <Box
        width={1}
        mt={5}
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-end",
            justifyContent: "flex-end",
            gap: 0.25,
            width: "35rem",
          }}
        >
          <Box
            pb={2.5}
            width={1}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              gap: 0.25,
            }}
          >
            <Typography variant="body1" color="#FFF" fontWeight={400}>
            &lt;Comapny Name&gt;
            </Typography>
            <Typography
              variant="h2"
              fontWeight={400}
              color="#FFF"
              sx={{ paddingBottom: "1.5rem" }}
            >
              Formative Usability Score
            </Typography>

            <Box
              fontWeight={500}
              fontSize={160}
              color="#fff"
              sx={{ textDecoration: "underline" }}
            >
              Low
            </Box>
          </Box>
          <Box
            py={2.5}
            width={1}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "start",
              gap: 0.25,
              borderTop: 1,
              borderColor: "#fff",
            }}
            marginBottom="10rem"
          >
            <Typography
              variant="h6"
              color="#FFF"
              fontWeight={300}
              sx={{ paddingBottom: "1.5rem" }}
            >
              What are the most significant usability issues preventing users
              from accomplishing their goals or resulting inefficiencies?
            </Typography>
            <Typography
              variant="h6"
              color="#FFF"
              fontWeight={300}
              sx={{ paddingBottom: "1.5rem" }}
            >
              What aspects of the product work well for the users? What do users
              find frustrating?
            </Typography>
            <Typography
              variant="h6"
              color="#FFF"
              fontWeight={300}
              sx={{ paddingBottom: "1.5rem" }}
            >
              What are the most common errors or mistakes users are making?
            </Typography>
            <Typography
              variant="h6"
              color="#FFF"
              fontWeight={300}
              sx={{ paddingBottom: "1.5rem" }}
            >
              Are improvements being made from one design iteration to the next?
            </Typography>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default FormativeUsabilityScore;
